import BasicLayout from "../../layouts/BasicLayout"
import BreadCrumbs from "../../components/common/BreadCrumbs"
import ScrollToTop from "../../components/common/ScrollToTop";
import BackToTopButton from "../../components/common/BackToTopButton";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default ({children}) => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/assets/js/main.js";
        document.body.appendChild(script);
    }, []);

    const themeColor = "#67a6dc"

    return (
        <ScrollToTop>
            <BasicLayout>
                <main>
                    <BreadCrumbs path='Solutions' title='Planview AdaptiveWork' />
                    <div>
                        <header className="section-header-little-margin" data-aos="fade-up">
                            <p> Planview AdaptiveWork software </p>
                            <h2 style={{color:themeColor}}> Connect and engage your workforce across the enterprise </h2>
                        </header>
                        
                        {/* "Connect and engage your workforce across the enterprise" section*/}
                        <section className="about">
                            <div className="container" >

                                <div className="row gx-0">
                                    <div className="col-lg-6 content d-flex align-items-center" data-aos="fade-right" data-aos-delay="200" style={{backgroundColor:'#3C3535'}}>
                                        <p>Planview AdaptiveWork enables PMOs and professional services delivery teams of all sizes to gain real-time visibility across all their work, automate workflows, proactively manage risks, and deliver greater business impact.</p>
                                    </div>

                                    <div className="col-lg-6 d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="300" style={{backgroundColor:themeColor, padding:'75px'}}>
                                        <img className='img-fluid' src="assets/img/planview-adaptivework-dark.svg" alt="planview changepoint" />
                                    </div>
                                </div>
                                <br />
                                <p style={{textAlign: 'center'}} data-aos="fade-up">
                                    For more information, please visit the official page: <a href="https://www.planview.com/products-solutions/products/clarizen/" target="_blank" style={{color:themeColor}}> Here!</a>
                                </p>
                                
                            </div>
                        </section>
                    </div>

                    <div hidden>
                        <section className="services" style={{marginTop:'0px', padding:'60px'}}>
                            <div className="container" data-aos="fade-up">
                                
                                <div className="row row-cols-1 row-cols-md-3 g-4">
                                    {/* 1st column card */}
                                    <div className="col" data-aos="zoom-in" data-aos-delay="100">
                                        <div className="card border-0 h-100">
                                            <div className="col-sm-8 h-100">
                                                <div className="card-body">
                                                    <h4 className="card-title">Real Time Visibility</h4>
                                                    <br />
                                                    <p className="card-text">Make accurate data-based decisions, with Planview AdaptiveWork’s 360° real-time visibility.</p>
                                                    <ul>
                                                        <li>Dynamic Reports and Dashboards</li>
                                                        <li>Portfolio Roadmaps</li>
                                                        <li>Goals Management</li>
                                                        <li>Slide Publisher</li>
                                                        <li>Risk Management</li>
                                                    </ul>                                   
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {/* 2nd column card */}
                                    <div className="col" data-aos="zoom-in" data-aos-delay="200">
                                        <div className="card border-0 h-100">
                                            <div className="col-sm-8 h-100 d-flex">
                                                <div className="card-body">
                                                    <h4 className="card-title">Configurability</h4>
                                                    <p className="card-text">Customize Planview AdaptiveWork to fit how your enterprise does business and reconfigure at any time.</p>
                                                    <ul>
                                                        <li>Flexible UI</li>
                                                        <li>Centralized Architecture & Data Model</li>
                                                        <li>Out-of-the-Box and Custom Templates</li>
                                                        <li>Custom Objects</li>
                                                        <li>Workflow Automation</li>
                                                        <li>Project Administration</li>
                                                    </ul>  
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    
                                    {/* 3rd column card */}
                                    <div className="col" data-aos="zoom-in" data-aos-delay="300">
                                        <div className="card border-0 h-100">
                                            <div className="col-sm-8 h-100">
                                                <div className="card-body">
                                                    <h4 className="card-title">Planning</h4>
                                                    <br />
                                                    <p className="card-text">Align to company strategy and ensure your workforce is as effective as possible, focused on delivery of the right things at the right time.</p>
                                                    <ul>
                                                        <li>Demand Management</li>
                                                        <li>Capacity Planning</li>
                                                        <li>Resource Management</li>
                                                        <li>Project-level Planning</li>
                                                        <li>Portfolio Management</li>
                                                    </ul> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {/* 4th column card */}
                                    <div className="col" data-aos="zoom-in" data-aos-delay="400">
                                        <div className="card border-0 h-100">
                                            <div className="col-sm-8 h-100 d-flex">
                                                <div className="card-body">
                                                    <h4 className="card-title">Work Management</h4>
                                                    <br />
                                                    <p className="card-text">Create a single source for all project information: Keep your internal and external teams connected, communicating, and collaborating, and keep stakeholders informed.</p>
                                                    <ul>
                                                        <li>Collaborative Work Management</li>
                                                        <li>Agile Task Management</li>
                                                        <li>Personalized Workflows</li>
                                                        <li>Mobile App</li>
                                                        <li>External Collaborator</li>
                                                    </ul> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* 5th column card */}
                                    <div className="col" data-aos="zoom-in" data-aos-delay="500">
                                        <div className="card border-0 h-100">
                                            <div className="col-sm-8 h-100 d-flex">
                                                <div className="card-body">
                                                    <h4 className="card-title">Financial Management</h4>
                                                    <br />
                                                    <p className="card-text">Capture all financials on a project – whether labor or non-labor, CAPEX vs. OPEX, billable vs. non-billable.</p>
                                                    <ul>
                                                        <li>Budget Tracking</li>
                                                        <li>Timesheets</li>
                                                        <li>Utilization Trend Analysis</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>
                    </div>

                    <div>
                        <header className="section-header-little-margin" data-aos="fade-up">
                            <p> Key Features </p>
                            <h2 style={{color: themeColor}}> How AdaptiveWork can help you to achieve your goals </h2>
                        </header>

                        <p style={{textAlign: 'center'}}>Below are the key features that will assist you to achieve successfully the enterprise goals</p>
    
                        <section className="collumns blue" >
                            <div className="container" data-aos="fade-up">

                                <div className="row main">
                                    <div className="col-lg-4 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4">   
                                            <h1>Real Time Visibility</h1> 
                                            <p>You will take the right decisions at the right time thanks to :</p>
                                            <div className="tab-content align-middle">
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: themeColor}} />
                                                    <p> <b>Real-time reporting</b> capabilities </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: themeColor}} />
                                                    <p> <b>Interactive</b> visualization </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: themeColor}} />
                                                    <p> <b>Risk</b> assessment and follow-up </p>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <div className="col-lg-8 d-flex align-self-center" data-aos="zoom-out" data-aos-delay="100">
                                        <img src="assets/img/solutions/clarizen/clarizen_visibility.png" className="img-fluid" alt=""/>
                                    </div>
                                </div>

                                <div className="row main">
                                    <div className="col-lg-8 d-flex align-self-center" data-aos="zoom-out" data-aos-delay="200">
                                        <img src="assets/img/solutions/clarizen/clarizen_configurability.png" className="img-fluid" alt=""/>
                                    </div>

                                    <div className="col-lg-4 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4">   
                                            <h1>Configurability</h1> 
                                            <p>The tool is completely configurable to match your enterprise business requirements :</p>
                                            <div className="tab-content align-middle">
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: themeColor}} />
                                                    <p> <b>Flexible UI</b>, intuitive the interface is flexible and intuitive that fits your way of working. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: themeColor}} />
                                                    <p> <b>Extensible</b> by custom objects, automation of workflow and task will save you time ! </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: themeColor}} />
                                                    <p> <b>Access</b> management. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="row main">
                                    <div className="col-lg-6 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4">   
                                            <h1>Planning</h1> 
                                            <p>Planning capabilities allow you to manage :</p>
                                            <div className="tab-content align-middle">
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: themeColor}} />
                                                    <p> Demands Vs capacity </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: themeColor}} />
                                                    <p> Projects planning : see instantly any impact on delay for better delivery anticipation. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: themeColor}} />
                                                    <p> Portfolios : enables impact analyses, reduce planning errors and improve synergies between the portfolios. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 d-flex align-self-center" data-aos="zoom-out" data-aos-delay="200">
                                        <img src="assets/img/solutions/clarizen/clarizen_planning.png" className="img-fluid img_round2 w-100" alt=""/>
                                    </div>
                                </div>

                                <div className="row main">
                                    <div className="col-lg-6 d-flex align-self-center justify-content-center" data-aos="zoom-out" data-aos-delay="200">
                                        <img src="assets/img/solutions/clarizen/clarizen_work_management.png" className="img-fluid img_round2" alt=""/>
                                    </div>

                                    <div className="col-lg-6 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4">   
                                            <h1> Work Management </h1> 
                                            <p>Collaboration is one of the key to success. AdaptiveWork allows :</p>
                                            <div className="tab-content align-middle">
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: themeColor}} />
                                                    <p> All teams (internal or external) to exchange and track information shared in <b>1 single collaboration place</b> by differents channels (attached items to any work, emails, embedded chat, social group discussion, notifications). </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: themeColor}} />
                                                    <p> To bring a better <b>agile task management</b> experience. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: themeColor}} />
                                                    <p> To keep synch with the <b>centralized digital proofing and approval process</b>. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: themeColor}} />
                                                    <p> To follow your work on the go thanks to the dedicated <b>Mobile App</b> ! </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row main">
                                    <div className="col-lg-6 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4">   
                                            <h1> Financial Management </h1> 
                                            <p> As projects have objectives and are planned accordingly whilst sticking to a budget, AdaptiveWork contributes to : </p>
                                            <div className="tab-content align-middle">
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: themeColor}} />
                                                    <p> Connect project financials (expenses / times) to actual project performance. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: themeColor}} />
                                                    <p> Help analysing balances cost against quality and risk for cost optimization strategies. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 d-flex align-self-center" data-aos="zoom-out" data-aos-delay="200">
                                        <img src="assets/img/consulting.jpeg" className="img-fluid img_round2" alt=""/>
                                    </div>
                                </div>

                                <div className="row main">
                                    <div className="col-lg-6 d-flex align-self-center justify-content-center" data-aos="zoom-out" data-aos-delay="200">
                                        <img src="assets/img/solutions/clarizen/clarizen_third_app.png" className="img-fluid img_round2 w-100" alt=""/>
                                    </div>

                                    <div className="col-lg-6 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4">   
                                            <h1> Open to third application  </h1> 
                                            <p> Access to AdaptiveWork app market and add functionalities by : </p>
                                            <div className="tab-content align-middle">
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: themeColor}} />
                                                    <p> Integrations (JIRA, Microsoft Teams, Slack, SharePoint, Salesforce, SAP…). </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: themeColor}} />
                                                    <p> Add-ins. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: themeColor}} />
                                                    <p> Mobile apps. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </section>
                    </div>

                    <BackToTopButton />

                </main>
            </BasicLayout>
        </ScrollToTop>
    );
}