import BasicLayout from "../layouts/BasicLayout"
import BreadCrumbs from "../components/common/BreadCrumbs"
import ScrollToTop from "../components/common/ScrollToTop"
import BackToTopButton from "../components/common/BackToTopButton"
import Team from "../components/about/Team"
import Timeline from "../components/about/Timeline"
import Counters from "../components/about/Counters"
import Values from "../components/about/Values"

export default () => {
    return (
        <ScrollToTop>
            <BasicLayout>
                <main>
                    <BreadCrumbs path='About' title='More about us' />
                    <Values />
                    <Counters />
                    <Team />
                    <Timeline />
                    <BackToTopButton />
                </main>
            </BasicLayout>
        </ScrollToTop>
    );
}