import BasicLayout from "../layouts/BasicLayout"
import BreadCrumbs from "../components/common/BreadCrumbs"
import ScrollToTop from "../components/common/ScrollToTop";
import BackToTopButton from "../components/common/BackToTopButton";
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default ({children}) => {
    const { t, i18n } = useTranslation();
    const { search, state } = useLocation();
    const queryParams = new URLSearchParams(search);
    const companyId = queryParams.get('company');

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/assets/js/main.js";
        document.body.appendChild(script);
        console.log("state = ", state)
    }, []);

    return (
        <ScrollToTop>
            <BasicLayout>
                <main>
                    <BreadCrumbs path='Success Cases' title={state.companyName} />
                    
                    <div className="container" style={{marginTop: '75px', marginBottom: '75px'}}>
                        <div className="row">
                            <div className="col-lg-6 align-items-center justify-content-center" data-aos="fade-right" data-aos-delay="200" style={{paddingRight: '30px'}} >
                                <div className="row" style={{ padding: '0px 35px 35px' }}>
                                    {state.flexDirection == "column" ? 
                                        <> 
                                            <div className="row mb-4">
                                                <div className=" d-flex align-items-center justify-content-center ">
                                                    <img className='img-fluid' src={state.logoPath} style={{maxWidth: state.maxLogoWidth}} alt={state.companyName + " logo"} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className=" d-flex align-items-center justify-content-center">
                                                    <div>{state.textParagraphs.map((paragraph) => <p>{paragraph}</p>)}</div>
                                                </div>
                                            </div>
                                        </> 
                                        : 
                                        <div className="row">
                                            <div className="col-lg-6 d-flex align-items-center justify-content-center">
                                                <img className='img-fluid' src={state.logoPath} style={{maxWidth: state.maxLogoWidth}} alt={state.companyName + " logo"} />
                                            </div>
                                            <div className="col-lg-6 d-flex align-items-center justify-content-center">
                                                <div>{state.textParagraphs.map((paragraph) => <p>{paragraph}</p>)}</div>
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className="row d-flex align-items-center justify-content-center">
                                    <div className="card border-color" style={{ borderColor: state.color, backgroundColor: "#F4F4F4"}}>
                                        <div className="card-body">
                                            <p>Company: <b>{state.companyName}</b></p>
                                            <p>Number of Employees: <b>{state.numberEmployees}</b></p>
                                            <p>Website: <b>{state.website}</b></p>
                                            <p>Country or Region: <b>{state.country}</b></p>
                                            <p>Industry: <b>{state.industry}</b></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-6 d-flex flex-column" data-aos="fade-left" data-aos-delay="300"  style={{paddingLeft: '30px'}}>
                                <div className="successCaseSummary flex-grow-1 mb-4">
                                    <h2 className="d-flex justify-content-center mb-3" style={{color: state.color}}>Challenge</h2>
                                    <p>{state.challenge}</p>
                                </div>

                                <div className="successCaseSummary flex-grow-1 mb-4">
                                    <h2 className="d-flex justify-content-center mb-3" style={{color: state.color}}>Solution Offered</h2>
                                    <p>{state.solutionOffered}</p>
                                </div>

                                <div className="successCaseSummary flex-grow-1 mb-4">
                                    <h2 className="d-flex justify-content-center mb-3" style={{color: state.color}}>Results</h2>
                                    <p>{state.resultsText}</p>
                                    <ul className="list-with-black-bullets">
                                        {state.resultsBulletPoints.map((bullet) => <li className="list-with-black-bullets">{bullet}</li>)}
                                    </ul>
                                </div>

                                <div className="d-flex justify-content-center">
                                    <p><a href={`/assets/successCasesPDFs/${state.id}.pdf`} style={{color: state.color}} download><b>Click here to download the case study!</b></a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                               
                    <BackToTopButton />

                </main>
            </BasicLayout>
        </ScrollToTop>
    );
}