import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function MaxWidthDialog() {
    const { t, i18n } = useTranslation();

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const titleStyle = {
        color: "#A52020"
    }

    /*
    //Commented this section because it disables the copy/paste from all the website
    document.addEventListener("copy", (evt) => {
        evt.clipboardData.setData("text/plain", "Copying is not allowed on this webpage");
        evt.preventDefault();
      }, false);
    */

  return (
    <React.Fragment>
        <Button 
            className='btn btn-primary btn-sm border-0' 
            variant="contained" 
            color="primary" 
            onClick={handleClickOpen}
            style={{backgroundColor:'#A52020'}}
        >
            {t('read_more')} 
            <i className="bi bi-arrow-right"></i>
        </Button>

        <Dialog
            fullWidth={true}
            maxWidth='xl'
            open={open}
            onClose={handleClose}
        >
            <h1 style={titleStyle} className="text-center">Logo Thought Process</h1>

            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: "#A52020",
                }}
                >
                <CloseIcon />
            </IconButton>

            <DialogContent>
                <div className="container">
                    <h3 style={titleStyle}> Story </h3>
                    <p> A logo that affirms the seriousness of the company through its raw forms. </p>
                    <p>  A verticality that allows gaining height : a company helping customers to evolve with high quality and rigotous support. </p>
                    <p> The dynamism is highlighted by the opacity games which also give a feeling of movement. The baseline is rounder in order to soften the general appearance and thus contributes to the reassurance. </p>

                    <h3 style={titleStyle}> Key Words </h3>
                    <div className="tab-content align-middle">
                        <div className="line d-flex align-items-center mb-2">
                            <i className="bi bi-check2"/>
                            <p>Serious</p>
                        </div>
                        <div className="line d-flex align-items-center mb-2">
                            <i className="bi bi-check2"/>
                            <p>Reassurance</p>
                        </div>
                        <div className="line d-flex align-items-center mb-2">
                            <i className="bi bi-check2"/>
                            <p>Growth</p>
                        </div>
                        <div className="line d-flex align-items-center mb-2">
                            <i className="bi bi-check2"/>
                            <p>Bussiness</p>
                        </div>
                        <div className="line d-flex align-items-center mb-2">
                            <i className="bi bi-check2"/>
                            <p>Building</p>
                        </div>
                        <div className="line d-flex align-items-center mb-2">
                            <i className="bi bi-check2"/>
                            <p>City</p>
                        </div>
                        <div className="line d-flex align-items-center mb-2">
                            <i className="bi bi-check2"/>
                            <p>International</p>
                        </div>
                    </div>
                    
                    <img src='assets/img/Logo-HB.png' className='img-fluid mx-auto d-block' style={{width:"30%", minWidth:"270px"}} alt="HB Logo"/>

                    <h3 style={titleStyle}>
                        Creative Process
                    </h3>
                    <div className="tab-content align-middle">
                        <div className="line d-flex align-items-center mb-2">
                            <i className="bi bi-check2"/>
                            <p>The use of the letters « hb », for their similarity, is a direct reminder of the company history, founded by a calm, solid couple, who started this adventure and knew how to grow both financially and geographically.</p>
                        </div>
                        <div className="line d-flex align-items-center mb-2">
                            <i className="bi bi-check2"/>
                            <p>The letters, once stripped of their horizontality, are identical.</p>
                        </div>
                        <div className="line d-flex align-items-center mb-2">
                            <i className="bi bi-check2"/>
                            <p>The bars symbolize both height gain and movement (cf. Sound bars in motion), the buildings of large cities generally bringing together large companies.</p>
                        </div>
                        <div className="line d-flex align-items-center mb-2">
                            <i className="bi bi-check2"/>
                            <p>The return of horizontality by the darker squares allows the legibility of the letters without hindering the verticals symbolism and simulates the movement by the triangular shadow and the « escape » of the last square... The story is not finished...</p>
                        </div>
                        <div className="line d-flex align-items-center mb-2">
                            <i className="bi bi-check2"/>
                            <p>The baseline rounds things off a bit and reassures the company’s flexibility.</p>
                        </div>
                    </div>
                    
                    <img src='assets/img/logo_process.jpeg' className='img-fluid mx-auto d-block' style={{width:"65%"}} alt="Logo evolution"/>
        
                </div>
            </DialogContent>

        </Dialog>
    </React.Fragment>
  );
}
