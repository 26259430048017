import './i18n/config';
import { BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App';
import About from './pages/AboutPage';
import Resources from './pages/Resources';
import PrivacyPolicy from './pages/Privacy';
import TermsOfService from './pages/Terms';
import Consulting from './pages/services/Consulting';
import Support from './pages/services/Support';
import Development from './pages/services/Development';
import Changepoint from './pages/solutions/Changepoint';
import Daptiv from './pages/solutions/Daptiv';
import Barometer from './pages/solutions/Barometer';
import Clarizen from './pages/solutions/Clarizen';
import Magnify from './pages/solutions/Magnify';
import Partners from './pages/Partners';
import AgilPlace from './pages/solutions/AgilPlace'
import SuccessCasesPage from './pages/SuccessCasesPage';
import Monday from './pages/solutions/Monday';

const container = document.getElementById('root')
const root = ReactDOM.createRoot(container)
root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element= {<Navigate to='/home'/> } />
            <Route path="home" element={<App />} />
            <Route path="about" element={<About />} />
            <Route path="consulting" element={<Consulting />} />
            <Route path="support" element={<Support />} />
            <Route path="development" element={<Development />} />
            <Route path="changepoint" element={<Changepoint />} />
            <Route path="daptiv" element={<Daptiv />} />
            <Route path="barometer" element={<Barometer />} />
            <Route path="clarizen" element={<Clarizen />} />
            <Route path="magnify" element={<Magnify />} />
            <Route path="monday" element={<Monday />} />
            <Route path="resources" element={<Resources />} />
            <Route path="privacy" element={<PrivacyPolicy />} />
            <Route path="terms" element={<TermsOfService />} />
            <Route path="partners" element={<Partners />} />
            <Route path="agilplace" element={<AgilPlace />} />
            <Route path="successCases" element={<SuccessCasesPage />} />
        </Routes>
    </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
