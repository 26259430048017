import BasicLayout from "../../layouts/BasicLayout"
import BreadCrumbs from "../../components/common/BreadCrumbs"
import ScrollToTop from "../../components/common/ScrollToTop";
import BackToTopButton from "../../components/common/BackToTopButton";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default ({children}) => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/assets/js/main.js";
        document.body.appendChild(script);
    }, []);

    const THEME_COLOR = "#FA1B51"
    const GREEN_COLOR = "#03CA72"
    const YELLOW_COLOR = "#FFCC01"

    return (
        <ScrollToTop>
            <BasicLayout>
                <main>
                    <BreadCrumbs path='Solutions' title='Monday.com' />
                    <div>
                        <header className="section-header-little-margin" data-aos="fade-up">
                            <p>Monday.com</p>
                            <h2 style={{color:THEME_COLOR}}>Shape your workflows, scale it to match your needs</h2>
                        </header>
                        <section className="about">
                            <div className="container" >
                                <div className="row gx-0">
                                    <div className="col-lg-6 content d-flex align-items-center" data-aos="fade-right" data-aos-delay="200" style={{backgroundColor:THEME_COLOR}}>
                                        <p><b>Monday.com</b> {t('is a web-based platform that offers team management and collaboration tools to help teams organize and streamline their work processes of marketing ,sales, product development, HR, PMO …')}</p>
                                    </div>

                                    <div className="borderedBox col-lg-6 d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="300" style={{backgroundColor: '#FFFFFF', padding:'75px'}}>
                                        <img className='img-fluid' src="assets/img/solutions/monday/monday.avif" alt="planview agile place" />
                                    </div>
                                </div>
                                <br />
                                <div style={{textAlign: 'center'}}>
                                    <p>Monday.com is a <b>one source of truth</b> for all your projects’ data.
                                    It is a <b>centralised platform</b> that does it all — communication, planning, resource allocation, reporting, and more.</p>
                                    <h1 >Try for free, <a href="https://monday.com/" target="_blank" style={{color:THEME_COLOR}}> here!</a></h1>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div>
                        <section className="collumns pink" >
                            <div className="container" data-aos="fade-up">
                                <div className="row main ">
                                    <div className="col-lg-12 d-flex justify-content-center" data-aos="zoom-out" data-aos-delay="200">
                                    </div>
                                </div>

                                <div className="row main">
                                    <div className="col-lg-6 d-flex align-self-center" data-aos="zoom-out" data-aos-delay="100">
                                        <img src="assets/img/solutions/monday/monday_use_cases.png" className="img-fluid img_round2" alt=""/>
                                    </div>

                                    <div className="col-lg-6 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4"> 
                                            <div className="tab-content align-middle">
                                                <h1>What can you do with it ?</h1>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <div id="oval_shape" style={{ backgroundColor: THEME_COLOR }}/>
                                                    <p className="spaced_paragraph"><b>Easily share feedback, reports, and updates</b> with your team and management, from one place</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <div id="oval_shape" style={{ backgroundColor: THEME_COLOR }}/>
                                                    <p className="spaced_paragraph"><b>Give visibility - </b> All stakeholders need into statuses, tasks, ownership, and files</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <div id="oval_shape" style={{ backgroundColor: THEME_COLOR }}/>
                                                    <p className="spaced_paragraph">Work with any PM methodology: <b>Agile, Waterfall, Hybrid, or your own unique methodology</b></p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <div id="oval_shape" style={{ backgroundColor: THEME_COLOR }}/>
                                                    <p className="spaced_paragraph">Help teams <b>move faster by automating manual work</b> like check-ins, due dates, calculations, etc</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row main">
                                    <div className="col-lg-6 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4">   
                                            <div className="tab-content align-middle">
                                                <h1>Key features</h1> 
                                                <div className="line d-flex align-items-center mb-2">
                                                    <div id="oval_shape" style={{ backgroundColor: YELLOW_COLOR }}/>
                                                    <p className="spaced_paragraph">Customizable board, View, Reporting and Analytics</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <div id="oval_shape" style={{ backgroundColor: YELLOW_COLOR }}/>
                                                    <p className="spaced_paragraph">Task management</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <div id="oval_shape" style={{ backgroundColor: YELLOW_COLOR }}/>
                                                    <p className="spaced_paragraph">Collaboration and Communication</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <div id="oval_shape" style={{ backgroundColor: YELLOW_COLOR }}/>
                                                    <p className="spaced_paragraph">Workflow automation</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <div id="oval_shape" style={{ backgroundColor: YELLOW_COLOR }}/>
                                                    <p className="spaced_paragraph">Document management</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <div id="oval_shape" style={{ backgroundColor: YELLOW_COLOR }}/>
                                                    <p className="spaced_paragraph">Security and permissions</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <video className="col-lg-6 d-flex align-self-center" autoPlay loop muted data-aos="zoom-out" data-aos-delay="200">
                                        <source src="https://dapulse-res.cloudinary.com/video/upload/q_auto,f_auto,cs_copy/Generator_featured images/Home Page - 2022 Rebrand/parallax/goals_dashboard.mp4" type="video/mp4"></source>
                                    </video>

                                    
                                </div>

                                <div className="row main">
                                    <div className="col-lg-6 d-flex align-self-center" data-aos="zoom-out" data-aos-delay="200">
                                        <img src="assets/img/solutions/monday/monday_integrations.png" className="img-fluid img_round2" alt=""/>
                                    </div>

                                    <div className="col-lg-6 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4">   
                                            <h1>Integrations is life !</h1> 
                                            <p>Monday propose you pre built Integrations with your favorite tools for différents purpose :</p>
                                            <div className="tab-content align-middle">
                                                <div className="line d-flex align-items-center mb-2">
                                                    <div id="oval_shape" style={{ backgroundColor: GREEN_COLOR }}/>
                                                    <p className="spaced_paragraph">Communication, Remote Work (Zoom, slack, Teams)</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <div id="oval_shape" style={{ backgroundColor: GREEN_COLOR }}/>
                                                    <p className="spaced_paragraph">Software development (GitLab, PagerDuty)</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <div id="oval_shape" style={{ backgroundColor: GREEN_COLOR }}/>
                                                    <p className="spaced_paragraph">Customer support (Zendesk, Clearbit…)</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <div id="oval_shape" style={{ backgroundColor: GREEN_COLOR }}/>
                                                    <p className="spaced_paragraph">Marketing (Facebook, LinkedIn, HubSport…)</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <div id="oval_shape" style={{ backgroundColor: GREEN_COLOR }}/>
                                                    <p className="spaced_paragraph">And more !</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row main">
                                    <div className="col-lg-6 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4">   
                                            <h1>Make your life easier with AUTOMATIONS !</h1> 
                                            <p>Monday.com have pre built automations related to status update, email notifications, dependencies, task assignement, time tracking, progress updates and lot more !</p>
                                            <div className="tab-content align-middle">
                                                <div className="line d-flex align-items-center mb-2">
                                                    <div id="oval_shape" style={{ backgroundColor: THEME_COLOR }}/>
                                                    <p className="spaced_paragraph">It is customizable</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <div id="oval_shape" style={{ backgroundColor: THEME_COLOR }}/>
                                                    <p className="spaced_paragraph">Can be integrated with third application</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 d-flex align-self-center" data-aos="zoom-out" data-aos-delay="200">
                                        <img src="assets/img/solutions/monday/monday_automations.png" className="img-fluid img_round2" alt=""/>
                                    </div>
                                </div>

                                <div className="row main">
                                    <div className="col-lg-6 d-flex align-self-center" data-aos="zoom-out" data-aos-delay="200">
                                        <img src="assets/img/solutions/monday/monday_document.png" className="img-fluid img_round2" alt=""/>
                                    </div>

                                    <div className="col-lg-6 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4">   
                                            <h1>Document management</h1> 
                                            <p>Maintain accessibility to your work documents to facilitate the creation, alignment and proactive approach across all aspects of your team's work.</p>
                                            <div className="tab-content align-middle">
                                                <div className="line d-flex mb-2 flex-column">
                                                    <div className="d-flex align-items-center">
                                                        <div id="oval_shape" style={{ backgroundColor: YELLOW_COLOR }}/>
                                                        <p className="spaced_paragraph">Board Integrations</p>
                                                    </div>
                                                    <div className="d-flex align-items-center" style={{marginLeft: '50px'}}>
                                                        <div id="oval_shape" style={{ backgroundColor: YELLOW_COLOR }}/>
                                                        <p className="spaced_paragraph">Real Time engine</p> 
                                                    </div>
                                                    <div className="d-flex align-items-center" style={{marginLeft: '50px'}}>
                                                        <div id="oval_shape" style={{ backgroundColor: YELLOW_COLOR }}/>
                                                        <p className="spaced_paragraph">Multiple editor and easy-to-use Editor</p> 
                                                    </div>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <div id="oval_shape" style={{ backgroundColor: YELLOW_COLOR }}/>
                                                    <p className="spaced_paragraph">Live update</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <div id="oval_shape" style={{ backgroundColor: YELLOW_COLOR }}/>
                                                    <p className="spaced_paragraph">Embedded elements</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <div id="oval_shape" style={{ backgroundColor: YELLOW_COLOR }}/>
                                                    <p className="spaced_paragraph">And more !</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row main">
                                    <div className="col-lg-6 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4">   
                                            <h1>Market place to make your work OS limitless !</h1> 
                                            <p>You can access to Monday market place to add new functionalities.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 d-flex align-self-center flex-column" data-aos="zoom-out" data-aos-delay="200">
                                        <img src="assets/img/solutions/monday/monday_marketplace.png" className="img-fluid img_round2" alt=""/>
                                    </div>
                                </div>

                            </div>
                        </section>
                    </div>

                    <BackToTopButton />

                </main>
            </BasicLayout>
        </ScrollToTop>
    );
}