import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

export default ({children}) => {
    const { t, i18n } = useTranslation();

    /*
    *   The scripts array and the useEffect() are used to solve the problem with 
    *   the loading of the swiper style on each page that it is used
    */
    const scripts = [
        "/assets/js/main.js",
    ]

    useEffect(() => {
        scripts.map((path) => {
          const script = document.createElement('script');
      
          script.src = path;
        
          document.body.appendChild(script);
          return script 
        })  
    }, []);
    
    return (
        <a href="#" className="back-to-top d-flex align-items-center justify-content-center">
            <i className="bi bi-arrow-up-short"></i>
        </a>
    );
}
