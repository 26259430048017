import Flags from 'country-flag-icons/react/3x2'
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useState } from 'react';
import  { useNavigate } from 'react-router-dom'
import { GoogleLogin } from 'react-google-login';
import { HashLink as Link } from 'react-router-hash-link';
import { useEffect } from 'react';

function Navbar(props) {
    const { t, i18n } = useTranslation();
    const history = useNavigate();

    const scripts = [
        "/assets/vendor/purecounter/purecounter.js",
        "/assets/js/main.js",
      ]

    useEffect(() => {
        scripts.map((path) => {
          const script = document.createElement('script');
      
          script.src = path;
        
          document.body.appendChild(script);
          return script 
        })  
      }, []);

    const menu = [
        {
            title: t('navbar_about'),
            href: '/home#about'
        },
        {
            title: t('navbar_services'),
            href: '/home#services',
            dropdown: [{
                    title: t('navbar_services_consulting'),
                    href: '/consulting'
                },
                {
                    title: t('navbar_services_support'),
                    href: '/support'
                },
                {
                    title: t('navbar_services_development'),
                    href: '/development'
                },
            ]
        },
        {
            title: t('navbar_solutions'),
            href: '/home#solutions',
            dropdown: [
                {
                    title: 'Magnify+',
                    href: '/magnify'
                },
                {
                    title: 'Planview Changepoint',
                    href: '/changepoint'
                },
                {
                    title: 'Planview AdaptiveWork',
                    href: '/clarizen'
                },
                {
                    title: 'Planview Agile Place',
                    href: '/agilplace'
                },
                {
                    title: 'Planview Barometer',
                    href: '/barometer'
                },
                {
                    title: 'Monday.com',
                    href: '/monday'
                }
            ]
        },
        {
            title: t('navbar_customers'),
            href: '/home#customers'
        },
        {
            title: t('navbar_contacts'),
            href: '/home#contacts',
        } 
    ]

    const languages = [
        {
            title: t('navbar_language'),
            dropdown: [{
                    title: 'GB',
                },
                {
                    title: 'PT',
                },
                {
                    title: 'FR',
                }
            ]
        }
    ]

    const [loginData, setLoginData] = useState(
        localStorage.getItem('loginData') ? JSON.parse(localStorage.getItem('loginData')) : null
    );

    const handleLogin = async (googleData) => {
        const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/server`, {
            method: 'POST',
            body: JSON.stringify({
                token: googleData.tokenId,
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        });

        const data = await res.json()
        console.log(data)
        if(!data.email) {
            alert(`Failed to login.`);
            console.log('User Unhautorized')
        } else {
            console.log(`Server response is ${JSON.stringify(data)}`)
            setLoginData(data)
            localStorage.setItem('loginData', JSON.stringify(data)) 
            history('/resources')
        }   
    };

    const handleLogout = () => {
        console.log('user is logging out')
        localStorage.removeItem('loginData')
        setLoginData(null)
        history('/')
    }
    
    const handleFailure = (res) => {
        console.log('Login failed: res:', res);
        alert(`Failed to login.`);
    };

    return(
        <header id="header" className="header fixed-top">
            <div className="container ">
                <nav id="navbar" className="navbar">
                    <a href="/home" className="logo">
                        <img src='assets/img/Logo-HB.png' alt=""/>
                    </a>

                    <div className='d-flex flex-row-reverse'>
                        <ul>
                            {menu.map((item, index) => 
                                item.dropdown ?
                                <li key={item.title} className="dropdown">
                                    <Link to={item.href}>    
                                        <span>{item.title}</span> 
                                        <i className="bi bi-chevron-down"></i>
                                    </Link>
                                    <ul>
                                        {item.dropdown.map((subItem) => 
                                            <li key={subItem.title}> <Link to={subItem.href}>{subItem.title}</Link> </li>
                                        )}
                                    </ul>
                                </li>
                                :
                                <li key={item.title}> <Link className="nav-link scrollto" to={item.href}>{item.title}</Link> </li>
                            )}

                            <li hidden={loginData ? false : true} key='resources'> <Link className="nav-link scrollto" to='/resources'>Resources</Link> </li>
                        
                            {/* Login and Logout Buttons */}
                            <li key='login'>
                                {
                                loginData ? (
                                    <div>
                                        {/* User can see Resources section because he is logged in */}
                                        {/* <ul className='login_section'> */}
                                            {/* <li key='welcome_message'> <a>{t('navbar_welcome', {name:loginData.name} )}</a> </li> */}
                                            <button onClick={handleLogout} className='login'>{t('navbar_logout')}</button>
                                        {/* </ul> */}
                                        
                                    </div>
                                ) : (
                                    <GoogleLogin
                                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                        render={renderProps => (
                                            <span>
                                                <button onClick={renderProps.onClick} disabled={renderProps.disabled} className='login'>{t('navbar_login')}</button>
                                            </span>
                                        )}
                                        onSuccess={handleLogin}
                                        onFailure={handleFailure}
                                        cookiePolicy={'single_host_origin'}
                                        prompt="select_account"
                                        icon={false}
                                    ></GoogleLogin>
                                ) 
                                }
                            </li> 

                            {/* Languages Dropdown Menu */}
                            {languages.map((item, index) =>
                                <li hidden key='languages' className="dropdown" >
                                    <img src='assets/img/language_logo.png' alt="languages" width='30' height='30' className="bi bi-chevron-down"/> 
                                    <ul className='languages'>
                                        <li key='english' onClick={() => i18n.changeLanguage('en')}>
                                            <Flags.GB title={t('navbar_language_english')} className="flag"/>
                                            {t('navbar_language_english')}
                                        </li>
                                        {/* TODO: To show PT and FR in the languages dropdown, just remove "hidden" attribute*/}
                                        <li hidden key='portuguese' onClick={() => i18n.changeLanguage('pt')}>
                                            <Flags.PT title={t('navbar_language_portuguese')} className="flag"/>
                                            {t('navbar_language_portuguese')}
                                        </li>
                                        <li hidden key='french' onClick={() => i18n.changeLanguage('fr')}>
                                            <Flags.FR title={t('navbar_language_french')} className="flag"/>
                                            {t('navbar_language_french')}
                                        </li>
                                    </ul>
                                </li>
                            )}
                        </ul>
                    </div>

                    <i className="bi bi-list mobile-nav-toggle"></i>

                </nav>
            </div>
        </header>     
    )
}

export default Navbar;