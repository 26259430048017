import BasicLayout from "../../layouts/BasicLayout"
import BreadCrumbs from "../../components/common/BreadCrumbs"
import ScrollToTop from "../../components/common/ScrollToTop";
import BackToTopButton from "../../components/common/BackToTopButton";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

/*
 * Note: this page is no longer being used.
*/
export default ({children}) => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/assets/js/main.js";
        document.body.appendChild(script);
    }, []);

    const gray = '#3C3535'

    return (
        <ScrollToTop>
            <BasicLayout>
                <main>
                    <BreadCrumbs path='Solutions' title='Planview Daptiv' />

                    <div>
                        <header className="section-header-little-margin" data-aos="fade-up">
                            <p> Planview Daptiv PPM software </p>
                            <h2> Plan, deliver, and adapt at the speed of business </h2>
                        </header>
                        
                        <section className="about">
                            <div className="container" data-aos="fade-up">

                                <div className="row gx-0">
                                    <div className="col-lg-6 content d-flex align-items-center" data-aos="fade-right" data-aos-delay="200" style={{backgroundColor:'#3C3535'}}>
                                        <p>Planview Daptiv enables PMOs to have the agility, alignment, and enterprise-awareness needed to deliver projects and programs while adapting to changing business priorities. <br/> <br/>
                                        Planview Daptiv is the industry leading ‘Software-as-a-Service’ (SaaS) PPM solution, which means significantly lower costs and a higher ROI for your organization. <br/> <br/>
                                        There are no servers, software updates or IT headaches to worry about and you can be up and running within days of signing up</p>
                                    </div>

                                    <div className="col-lg-6 d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="300" style={{backgroundColor:'#A52020', padding:'75px'}}>
                                        <img className='img-fluid' src="assets/img/solutions/daptiv_white.png" alt="planview daptiv"/>
                                    </div>
                                </div>
                                <br />
                                <p style={{textAlign: 'center'}} >
                                    For more information, please visit the official page: <a href="https://www.planview.com/products-solutions/products/daptiv/" target="_blank"> Here!</a>
                                </p>
                                
                            </div>
                        </section>            
                    </div>

                    {/* Colummns with info about daptiv's tool */}
                    <div>
                        <section className="collumns" >
                            <div className="container">

                                <div className="row">

                                    {/* Project and Programs */}
                                    <div className="col-lg-6 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col gy-4">   
                                            <h1 className="d-flex justify-content-center">Project and Programs</h1> 
                                            <p>A comprehensive solution for project and program management, tracking work, and improving the PMO’s delivery and time-to-market.</p>
                                            <div className="tab-content align-middle">
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: gray}} />
                                                    <p>Configurability</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: gray}} />
                                                    <p>Templates</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: gray}} />
                                                    <p>Resource Planning</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: gray}} />
                                                    <p>Program Management</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: gray}} />
                                                    <p>Control Project Costs</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Dashboards and Analytics */}
                                    <div className="col-lg-6 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col gy-4">   
                                            <h1 className="d-flex justify-content-center">Dashboards and Analytics</h1> 
                                            <p>Enable faster, accurate decision-making with intuitive dashboards, insights, and advanced analytics.</p>
                                            <div className="tab-content align-middle">
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: gray}} />
                                                    <p>Embedded Dashboards</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: gray}} />
                                                    <p>Out-of-the-Box Analytics</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: gray}} />
                                                    <p>Configurable, Easy to Use Insights and Stakeholder Views</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: gray}} />
                                                    <p>Single Source of Truth</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: gray}} />
                                                    <p>Data Visualization with BI Integrations</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Portfolios Management */}
                                    <div className="col-lg-6 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col gy-4">   
                                            <h1 className="d-flex justify-content-center">Portfolios Management</h1> 
                                            <p>Assess, prioritize, and plan project portfolios through continuous collaboration. Map projects to strategy and manage and monitor progress through easy-to-use tools.</p>
                                            <p>Idea Intake and Work Demand Management:</p>
                                            <div className="tab-content align-middle">
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: gray}} />
                                                    <p>360° Resource Management</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: gray}} />
                                                    <p>Roadmapping</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: gray}} />
                                                    <p>Scenario Planning</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: gray}} />
                                                    <p>Assess Impacts</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: gray}} />
                                                    <p>Strategy to Execution Alignment</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Demand Intake */}
                                    <div className="col-lg-6 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="100">
                                        <div className="col gy-4">   
                                            <h1 className="d-flex justify-content-center">Demand Intake</h1> 
                                            <p>Clearly map ideas, work requests, and demand to the right projects and initiatives, visually manage the portfolio lifecycle, explore multiple prioritization and roadmap scenarios, evaluate capacity, trade-offs, and more.</p>
                                            <div className="tab-content align-middle">
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: gray}} />
                                                    <p>Idea Intake with Kanban Boards</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: gray}} />
                                                    <p>Priority Boards</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: gray}} />
                                                    <p>Roadmap with Constraint Analysis</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Resource Management */}
                                    <div className="col-lg-6 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col gy-4">   
                                            <h1 className="d-flex justify-content-center">Resource Management</h1> 
                                            <p>Matching resources to demand can be a complex exercise when projects are competing for a limited set of resources. Planview Daptiv offers both top-down and bottom-up resource planning to drive improved project outcomes.</p>
                                            <div className="tab-content align-middle">
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: gray}} />
                                                    <p>Optimize Resources</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: gray}} />
                                                    <p>Capacity Planning</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: gray}} />
                                                    <p>Resource and Utilization Analytics</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: gray}} />
                                                    <p>Role-based Allocation</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: gray}} />
                                                    <p>Task Schedules</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: gray}} />
                                                    <p>Timesheets</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </section>
                    </div>

                    {/* "From demand to delivery, end to end design" section */}
                    <div>
                        <header className="section-header-little-margin" data-aos="fade-up">
                            <p> Covers all PPM processes </p>
                            <h2> From demand to delivery, end to end design </h2>
                        </header>   

                        <section>
                            <div className="container" data-aos="fade-up">

                                <div style={{textAlign:'center'}}>
                                    <p> Daptiv helps you to manage your project full lifecycle, from demand to benefits realization, covering all main aspects of you organization PPM needs. </p>
                                    <img className='img-fluid w-75' src="assets/img/solutions/daptiv/daptiv_PPM_process.png" alt="Daptiv PPM process"/>
                                </div>
                               
                            </div>
                        </section>
                    </div>

                    {/* "CENTRALIZED MANAGEMENT FOR PORTFOLIOS AND PROJECTS" section */}
                    <div>
                        <header className="section-header-little-margin" data-aos="fade-up">
                            <p> Core PPM application  </p>
                            <h2> Centralized management for portfolios and projects </h2>
                        </header>   

                        <section>
                            <div className="container" data-aos="fade-up">

                                <div className="row">
                                     <div className="col-lg-8 d-flex align-self-center" data-aos="fade-up" data-aos-delay="100">
                                        <img src="assets/img/solutions/daptiv/daptiv_PPM_app.png" className="img-fluid w-100" alt="Daptiv core PPM app"/>
                                    </div>

                                    <div className="col-lg-4 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4">   
                                            <div className="tab-content align-middle">
                                                <p> Daptiv is a PPM solution that allows project managers to oversee a variety of current and future projects within an organization. </p>
                                                <br />
                                                <p> The goal of project portfolio management is to provide a comprehensive view of details from financials to resources and show how projects relate to each other. </p>
                                                <br />
                                                <p> Here are a few key benefits of using Daptiv: </p>
                                                <br />
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: gray}} />
                                                    <p>Allows leadership to have a macro-level view of project progress</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: gray}} />
                                                    <p>Helps teams keep tasks in perspective and understand priorities</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: gray}} />
                                                    <p>Assists in controlling costs and managing budget</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: gray}} />
                                                    <p>Helps with resource allocation and prevents team-wide burnout</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    
                                </div>

                            </div>
                        </section>
                    </div>

                    <BackToTopButton />

                </main>
            </BasicLayout>
        </ScrollToTop>
    );
}