import { useEffect } from 'react';
import Home from './pages/HomePage';
import React from "react";

const scripts = [
  /*"/assets/vendor/swiper/swiper-bundle.min.js",
  "/assets/vendor/purecounter/purecounter.js",
  "/assets/vendor/aos/aos.js",
  "/assets/vendor/bootstrap/js/bootstrap.bundle.min.js",
  "/assets/vendor/glightbox/js/glightbox.min.js",
  "/assets/vendor/isotope-layout/isotope.pkgd.min.js",
  "/assets/vendor/php-email-form/validate.js",*/
  "/assets/vendor/purecounter/purecounter.js",
  "/assets/js/main.js",
]

function App() {
  useEffect(() => {
    scripts.map((path) => {
      const script = document.createElement('script');
      script.src = path;
      document.body.appendChild(script);
      return script 
    })  
  }, []);

  return (
    <Home />
  );
}

export default App;
