import BasicLayout from "../../layouts/BasicLayout"
import BreadCrumbs from "../../components/common/BreadCrumbs"
import ScrollToTop from "../../components/common/ScrollToTop";
import BackToTopButton from "../../components/common/BackToTopButton";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default ({children}) => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/assets/js/main.js";
        document.body.appendChild(script);
    }, []);

    const red = '#A52020'
    const yellow = '#E8AC14'

    return (
        <ScrollToTop>
            <BasicLayout>
                <main>
                    <BreadCrumbs path='Solutions' title='Planview Changepoint' />
                    {/* "Power the quote-to-cash lifecycle" section*/}
                    <div>
                        <header className="section-header-little-margin" data-aos="fade-up">
                            <p> Planview Changepoint Services Automation software </p>
                            <h2> Power the quote-to-cash lifecycle </h2>
                        </header>
                        
                        <section className="about">
                            <div className="container" >

                                <div className="row gx-0">
                                    <div className="col-lg-6 content d-flex align-items-center" data-aos="fade-right" data-aos-delay="200" style={{backgroundColor:'#3C3535'}}>
                                        <p>Planview Changepoint enables <b>end-to-end professional services automation</b> for services-first businesses to streamline quote-to-cash and drive profitability. <br /> <br />
                                        Whether you are searching for Professional Services Automation (PSA), Services Resource Planning (SRP) or New Product Development (NPD), the solution is still the same. Changepoint PSA software allows you to effectively manage all aspects of your services organization. <br /> <br />
                                        Changepoint's integrated PSA solution provides complete project financial management, including total operational visibility, more consistent and reliable data, streamlined invoicing and in-depth financial reporting, allowing financial executives the ability to maintain top-line revenues with bottom-line profitability.</p>
                                    </div>

                                    <div className="col-lg-6 d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="300" style={{backgroundColor:'#A52020', padding:'75px'}}>
                                        <img className='img-fluid' src="assets/img/solutions/changepoint-logo-white.svg" alt="planview changepoint"/>
                                    </div>
                                </div>
                                <br />
                                <p style={{textAlign: 'center'}} data-aos="fade-up">
                                    For more information, please visit the official page: <a href="https://www.planview.com/products-solutions/products/changepoint/" target="_blank"> Here!</a>
                                </p>
                                
                            </div>
                        </section>
                    </div>

                    {/* Colummns with images from changepoint's tool */}
                    <div>
                        <header className="section-header-little-margin" />
                           
                        <section className="collumns" >
                            <div className="container" data-aos="fade-up">

                                <div className="row main">
                                    <div className="col-lg-8 d-flex align-self-center" data-aos="zoom-out" data-aos-delay="100">
                                        <img src="assets/img/solutions/changepoint/Project_hidden.png" className="img-fluid img_round2" alt=""/>
                                    </div>

                                    <div className="col-lg-4 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4">   
                                            <h1>Project Management</h1> 
                                            <h2>Build project planning</h2>  
                                            <div className="tab-content align-middle">
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Plan and delegate work in one place. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Manage tasks, subtasks, assignments, workflows, and calendars. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Build a better way of working with your team. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Drive demand and capacity. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Track and assess productivity and growth through resource management and reporting. </p>
                                                </div>
                                            </div> 

                                        </div>
                                    </div>
                                </div>

                                <div className="row main">
                                    <div className="col-lg-4 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4">   
                                            <h1>Financial Management</h1> 
                                            <h2>Keep costs and revenue under control</h2> 
                                            <div className="tab-content align-middle">
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Consolidate cost and revenue by project, contract and customers. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Keep track on all costs (time, expense, product) billable or not billable. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Manage invoices easely with contract and actual data. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Drive financial integrity. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Manage amounts in any currency. </p>
                                                </div>
                                            </div>
                     
                                        </div>
                                    </div>

                                    <div className="col-lg-8 d-flex align-self-center" data-aos="zoom-out" data-aos-delay="200">
                                        <img src="assets/img/solutions/changepoint/Budget_hidden.png" className="img-fluid img_round2" alt=""/>
                                    </div>
                                </div>

                                <div className="row main">
                                    <div className="col-lg-8 d-flex align-self-center" data-aos="zoom-out" data-aos-delay="200">
                                        <img src="assets/img/solutions/changepoint/Contract_hidden.png" className="img-fluid img_round2" alt=""/>
                                    </div>

                                    <div className="col-lg-4 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4">   
                                            <h1>Contract Management</h1> 
                                            <h2>Manage in one place all contracts information</h2> 
                                            <div className="tab-content align-middle">
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Define billing details and methods. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Manage invoices. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Define fixed fee schedule. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Recognize revenue. </p>
                                                </div>
                                            </div> 
                                
                                        </div>
                                    </div>
                                </div>

                                <div className="row main">
                                    <div className="col-lg-4 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4">   
                                            <h1>Customer Lifecycle Management</h1> 
                                            <h2>Clear, end-to-end view that drives financially successful customer-facing projects</h2> 
                                            <div className="tab-content align-middle">
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Projects and contracts are created on customers. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Manage customer statuses. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Manage customer contacts. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Consolidate data by customer or group. </p>
                                                </div>
                                            </div> 
                                
                                        </div>
                                    </div>

                                    <div className="col-lg-8 d-flex align-self-center" data-aos="zoom-out" data-aos-delay="200">
                                        <img src="assets/img/solutions/changepoint/ClientCP_hidden.png" className="img-fluid img_round2" alt=""/>
                                    </div>
                                </div>

                                <div className="row main">
                                    <div className="col-lg-8 d-flex align-self-center" data-aos="zoom-out" data-aos-delay="200">
                                        <img src="assets/img/solutions/changepoint/Dashboard.png" className="img-fluid img_round2" alt=""/>
                                    </div>

                                    <div className="col-lg-4 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4">   
                                            <h1>Kpis and Dashboards</h1> 
                                            <h2>Easily access all your data</h2> 
                                            <div className="tab-content align-middle">
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Increase communication. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Improves productivity, accuracy and timeliness. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Improves decision-making and efficiency within organization. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Identifying potential problems early, when you still have time to act on them. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Cost efficiency. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Customer focus. </p>
                                                </div>
                                            </div> 
                                            
                                        </div>
                                    </div>
                                </div>

                                <div className="row main">
                                    <div className="col-lg-4 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4">   
                                            <h1>Opportunity Management</h1> 
                                            <h2>Improve opportunity transformation in contracts</h2> 
                                            <div className="tab-content align-middle">
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Follow Up all your opportunities in one place. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Budget cost and revenue to evaluate potential margin. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Anticipate resource demand. </p>
                                                </div>
                                            </div> 
                           
                                        </div>
                                    </div>

                                    <div className="col-lg-8 d-flex align-self-center" data-aos="zoom-out" data-aos-delay="200">
                                        <img src="assets/img/solutions/changepoint/Opportunity.webp" className="img-fluid img_round2" alt=""/>
                                    </div>
                                </div>

                                <div className="row main">
                                    <div className="col-lg-8 d-flex align-self-center justify-content-center" data-aos="zoom-out" data-aos-delay="200">
                                        <img src="assets/img/solutions/changepoint/Workflow.png" className="img-fluid img_round2" style={{height: '500px'}} alt=""/>
                                    </div>

                                    <div className="col-lg-4 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4">   
                                            <h1>Workflow Management</h1> 
                                            <h2>Define simple to complex workflows</h2>
                                            <div className="tab-content align-middle">
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Define approval process. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Automatic action. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Automatic emails. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Automatic workflow progress triggered on field value or date. </p>
                                                </div>
                                            </div>  
                                            
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-4 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4">   
                                            <h1>Resource Management</h1> 
                                            <h2>Easily manage capacity and demand management</h2> 
                                            <div className="tab-content align-middle">
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Eliminates the danger of overbooking, avoiding unnecessary delays. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Allows managers use less resources more efficiently. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Makes project planning more transparent and helps avoid miscommunications. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Helps you spot problems early on and sort them out with time to spare. </p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p> Gives a more complete picture of how projects are progressin. </p>
                                                </div>
                                            </div>  
                                            
                                        </div>
                                    </div>

                                    <div className="col-lg-8 d-flex align-self-center" data-aos="zoom-out" data-aos-delay="200">
                                        <img src="assets/img/solutions/changepoint/Resource_hidden.png" className="img-fluid img_round2" alt=""/>
                                    </div>
                                </div>

                            </div>
                        </section>
                    </div>

                    {/* "What are our added value?" section */}
                    <div>
                        <header className="section-header-little-margin" data-aos="fade-up">
                            <p> Changepoint Certified Services </p>
                            <h2> What are our added value? </h2>
                        </header>
                        
                        <section className="about" style={{marginTop:'0px', padding:'0px 60px 60px 60px'}}>
                            <div className="container" data-aos="fade-up">
                                <p style={{textAlign: 'center'}}>You can count on our trust certified changepoint experts to support you : </p>
                            </div>

                            <div className="row row-cols-1 row-cols-md-2 g-4">
                                {/* 1st line and 1st column card */}
                                <div className="col" data-aos="zoom-in" data-aos-delay="100" style={{padding: '25px'}}>
                                    <div className="card border-color h-100" style={{borderColor:yellow}}>
                                        <div className="row g-0 h-100">
                                            <div className="col-sm-8 h-100">
                                                <div className="card-body text-end">
                                                    <p className="card-text">We can help you to <b>define, plan and implement</b> Changepoint so that it can fulfill your requirements.</p>
                                                    <p className="card-text">Having years of  implementation experience across industries, our team is capable of making your project a success in terms of objectives, cost and planning.</p>
                                                </div>
                                            </div>

                                            <div className="col-sm-4 d-flex align-items-center justify-content-center" style={{backgroundColor:yellow}}>
                                                <p className="changepoint_table_title_p">Implementation</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                {/* 1st line and 2nd column card */}
                                <div className="col" data-aos="zoom-in" data-aos-delay="200" style={{padding: '25px'}}>
                                    <div className="card border-color h-100" style={{borderColor:red}}>
                                        <div className="row g-0">
                                            <div className="col-sm-4 d-flex align-items-center justify-content-center" style={{backgroundColor:red}}>
                                                <p className="changepoint_table_title_p">Development</p>
                                            </div>

                                            <div className="col-sm-8">
                                                <div className="card-body">
                                                    <p className="card-text">Changepoint is a solution that can be easily extended with custom developments and we can handle those.</p>
                                                    <p className="card-text">Starting from <b>specification</b> and including <b>development, QA</b> and source control, we have years of experience (including helping the supplier in developing those custom for end-customers).</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* 2nd line and 1st column card */}
                                <div className="col" data-aos="zoom-in" data-aos-delay="300" style={{padding: '25px'}}>
                                    <div className="card border-color h-100" style={{borderColor:red}}>
                                        <div className="row g-0 h-100">
                                            <div className="col-sm-8 h-100 d-flex align-items-center">
                                                <div className="card-body text-end">
                                                    <p className="card-text" style={{margin:"0"}}>We can <b>develop any integration</b> with any third-party system, making usage of Changepoint API, Integration Framework or building a custom integration with middleware, staging databases or web services.</p>
                                                </div>
                                            </div>

                                            <div className="col-sm-4 d-flex align-items-center justify-content-center" style={{backgroundColor:red}}>
                                                <p className="changepoint_table_title_p">Integration</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                {/* 2nd line and 2nd column card */}
                                <div className="col" data-aos="zoom-in" data-aos-delay="400" style={{padding: '25px'}}>
                                    <div className="card border-color h-100" style={{borderColor:yellow}}>
                                        <div className="row g-0">
                                            <div className="col-sm-4 d-flex align-items-center justify-content-center" style={{backgroundColor:yellow}}>
                                                <p className="changepoint_table_title_p">Support</p>
                                            </div>

                                            <div className="col-sm-8">
                                                <div className="card-body">
                                                    <p className="card-text">Implementations don’t finish with go-live, based on our experience a good <b>maintenance and support</b> process is key to take full advantage of the investment done.</p>
                                                    <p className="card-text">We can manage your solution (Support/Administration), advice you for new processes implementation and answer all questions you may have when using the product.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </section>
                    </div>

                    <BackToTopButton />
                    
                </main>
            </BasicLayout>
        </ScrollToTop>
    );
}