import { useTranslation } from 'react-i18next';

export default (props) => {
    const { t, i18n } = useTranslation();
    
    return(
        <section id="faq" className="faq">
            <div className="container" data-aos="fade-up">
                <div className="accordion accordion-flush" id="faqlist1">

                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-1">
                                <div className="col-md-2" data-aos="fade-right" data-aos-delay="100">
                                    <img src="assets/img/icons/traiter.png" className="img-fluid" alt="Collaboration" />
                                </div>
                                <div className="col-md-9 text-center">
                                    COLLABORATION
                                </div>
                            </button>
                        </h2>
                        
                        <div id="faq-content-1" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                            <div className="accordion-body">
                                We are collaborating with partners and customers since the creation of the company. This is our strength !
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-2">
                                <div className="col-md-2" data-aos="fade-right" data-aos-delay="100">
                                    <img src="assets/img/icons/international.png" className="img-fluid" alt="International" />
                                </div>
                                <div className="col-md-9 text-center">
                                    INTERNATIONAL
                                </div>
                            </button>
                        </h2>
                        <div id="faq-content-2" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                            <div className="accordion-body">
                                We are an international team based in Portugal and France. We love to work with customer over the world, meeting new cultures and creating links between people !
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-3">
                                <div className="col-md-2" data-aos="fade-right" data-aos-delay="100">
                                    <img src="assets/img/icons/nearby.png" className="img-fluid" alt="Remotely Close" />
                                </div>
                                <div className="col-md-9 text-center">
                                    REMOTELY CLOSE
                                </div>
                            </button>
                        </h2>
                        <div id="faq-content-3" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                            <div className="accordion-body">
                                Working remotely does not mean long distance from the Client. It is in the company DNA that we believe on our team to work where they want and to be flexible to have the right balance between personal and professional life !
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-4">
                                <div className="col-md-2" data-aos="fade-right" data-aos-delay="100">
                                    <img src="assets/img/icons/solution.png" className="img-fluid" alt="Remotely Close" />
                                </div>
                                <div className="col-md-9 text-center">
                                    ADAPTABILITY
                                </div>
                            </button>
                        </h2>
                        <div id="faq-content-4" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                            <div className="accordion-body">
                                We are always looking for new challenges and we like to continually learn and work in different contexts and business areas.
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
}