import BasicLayout from "../../layouts/BasicLayout"
import BreadCrumbs from "../../components/common/BreadCrumbs"
import ScrollToTop from "../../components/common/ScrollToTop";
import BackToTopButton from "../../components/common/BackToTopButton";
import { HashLink as Link } from 'react-router-hash-link';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default ({children}) => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/assets/js/main.js";
        document.body.appendChild(script);
    }, []);

    const violet = '#7a74ff'
    const darkBlue = '#0a2440'
    const blue = '#014AE5'
    const white = '#FFF'

    return (
        <ScrollToTop>
            <BasicLayout>
                <main>
                    <BreadCrumbs path='Solutions' title='Magnify+' />
                    {/* "Protect your properties with Magnify+" section */}
                    <div>
                        <header className="section-header-little-margin" data-aos="fade-up">
                            <p> Protect your properties with Magnify+ </p>
                            <h2 style={{color:violet}}> Counter intellectual property infringements with the power of AI </h2>
                        </header>
                        
                        <section className="about">
                            <div className="container" data-aos="fade-up">

                                <div className="row gx-0">
                                    <div className="col-lg-6 content d-flex align-items-center" data-aos="fade-right" data-aos-delay="200" style={{backgroundColor:violet}}>
                                        <p><span style={{fontWeight: "900"}}>Magnify+</span> is a comprehensible intellectual property solution that allow you to search on different internet places any brand or terms, to manage applications / trademark and monitor their use. </p>
                                    </div>

                                    <div className="col-lg-6 d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="300" style={{backgroundColor:darkBlue, padding:'75px'}}>
                                        <img className='img-fluid' src="assets/img/solutions/magnify_white.png" alt="magnify+" />
                                    </div>
                                </div>
                                <br />
                                <div style={{textAlign: 'center'}}>
                                    <p> <b>Magnify+</b> grants users the power to define and separate the noise by detecting fraudulent activities like domain hijacking, counterfeit goods, cybersquatting and offers endless possibilities.</p>
                                    <p> To request a demo, <Link to='/home#contacts' style={{color: violet}}>please contact us.</Link> </p>
                                </div>
                            </div>
                        </section>
                    </div>

                    {/* "Use cases" section */}
                    <div>
                        <header className="section-header-little-margin" data-aos="fade-up">
                            <p> Use Cases </p>
                            <h2 style={{color: violet}}> Some of the use cases Magnify+ can help you with </h2>
                        </header>
    
                        <section>
                            <div className="container" data-aos="fade-up">

                                <div className="row gy-5">
                                    <div className="col-lg-4 col-md-6">
                                        <div className="card h-100 w-100" style={{backgroundColor:violet, color:white}}>
                                            <div className="card-body" style={{minHeight: '180px'}}>
                                                <h4 className="d-flex align-items-center justify-content-center"> Copyright Infringements </h4>
                                                <ul className="white">
                                                    <li> Unauthorized distribution </li>
                                                    <li> Piracy </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6">
                                        <div className="card h-100 w-100" style={{backgroundColor:darkBlue, color:white}}>
                                            <div className="card-body" style={{minHeight: '180px'}}>
                                                <h4 className="d-flex align-items-center justify-content-center"> Trademark Infringementss </h4>
                                                <ul className="white">
                                                    <li> Counterfeit goods </li>
                                                    <li> Selling merchandise and services with unauthorized emblems </li>
                                                    <li> Unlawful use </li>
                                                </ul>
                                                
                                                
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6">
                                        <div className="card h-100 w-100" style={{backgroundColor:blue, color:white}}>
                                            <div className="card-body" style={{minHeight: '180px'}}>
                                                <h4 className="d-flex align-items-center justify-content-center">Employee/Brand Impersonation</h4>
                                                <ul className="white">
                                                    <li> Phishing schemes </li>
                                                    <li> Fake website </li>
                                                    <li> Fake job vacancies </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6">
                                        <div className="card h-100 w-100" style={{backgroundColor:blue, color:white}}>
                                            <div className="card-body d-flex align-items-center justify-content-center" style={{minHeight: '180px'}}>
                                                <h4>IP audits, trademark in use searches, detect parallel imports, ...</h4>
                                                <br />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6">
                                        <div className="card h-100 w-100" style={{backgroundColor:violet, color:white}}>
                                            <div className="card-body d-flex align-items-center justify-content-center" style={{minHeight: '180px'}}>
                                                <h4>Branding compliance reviews with distributors, franchisees, social media influencers, ...</h4>
                                                <br />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6">
                                        <div className="card h-100 w-100" style={{backgroundColor:darkBlue, color:white}}>
                                            <div className="card-body d-flex align-items-center justify-content-center" style={{minHeight: '180px'}}>
                                                <h4>Design infringements</h4>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                               

                            </div>
                        </section>
                    </div>

                    {/* Colummns with images for key features */}
                    <div>
                        <header className="section-header-little-margin" data-aos="fade-up">
                            <p> Powerfull </p>
                            <h2 style={{color:violet}}> Key Features of Magnify+ </h2>
                        </header>

                        <section className="collumns_violete_title" >
                            <div className="container" data-aos="fade-up">

                                <p className='text-center'> Magnify+ is a SaaS platform with different functionalities that can perform on-off searches and watches, tailor made for you and your clients’ budgets. </p>
                                <br />

                                <div className="row">
                                    <div className="col-lg-4 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4">   
                                            <h1>Versatility</h1> 
                                            <div className="tab-content align-middle">
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: violet}} />
                                                    <p>Use case agnostic platform that can be easily configured in view of the user’s needs</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: violet}} />
                                                    <p>One-off search reports versus full monitoring program</p>
                                                </div>
                                            </div>
                                            
                                            <br />
                                            <h1>Modular Subscriptions</h1>
                                            <div className="tab-content align-middle">
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: violet}} />
                                                    <p>Add services and add-ons to your needs</p>
                                                </div>
                                            </div> 

                                        </div>
                                    </div>

                                    <div className="col-lg-8 d-flex align-self-center" data-aos="zoom-out" data-aos-delay="100">
                                        <img src="assets/img/solutions/magnify_versatility.png" className="img-fluid img_round" alt=""/>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-8 d-flex align-self-center" data-aos="zoom-out" data-aos-delay="200">
                                        <img src="assets/img/solutions/magnify_dynamic.png" className="img-fluid img_round2" alt=""/>
                                    </div>

                                    <div className="col-lg-4 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4">   
                                            <h1>Dynamic Search/Monitor Options</h1> 
                                            
                                            <div className="tab-content align-middle">
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: violet}} />
                                                    <p>Unlimited choice of channels (marketplaces, social media, …)</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: violet}} />
                                                    <p>Unrestricted choice of periodicity</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: violet}} />
                                                    <p>Free choice of jurisdiction, languages, scripts, …</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-4 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4">   
                                            <h1>Simplicity</h1> 

                                            <div className="tab-content align-middle">
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: violet}} />
                                                    <p>Integrated workflow</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: violet}} />
                                                    <p>Access all your results with different views that can be tailored to your needs</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: violet}} />
                                                    <p>Assign tasks to users</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: violet}} />
                                                    <p>Automated e-mail notifications when new results, new report, … become available</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: violet}} />
                                                    <p>Unlimited export of results (MS Word, MS Excel)</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: violet}} />
                                                    <p>Directly send reports to clients via e-mail</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: violet}} />
                                                    <p>Tag search results with calls to action</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-8 d-flex align-self-center" data-aos="zoom-out" data-aos-delay="200">
                                        <img src="assets/img/solutions/magnify_simplicity.png" className="img-fluid img_round2" alt=""/>
                                    </div>
                                </div>

                                <div className="row" style={{marginBottom: "0px"}}>
                                    <div className="col-lg-8 d-flex align-self-center" data-aos="zoom-out" data-aos-delay="200">
                                        <img src="assets/img/solutions/what_else.png" className="img-fluid img_round2" alt=""/>
                                    </div>

                                    <div className="col-lg-4 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4">   
                                            <h1>What Else?</h1> 

                                            <div className="tab-content align-middle">
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: violet}}/>
                                                    <p>Cloud based SaaS environment – no on prem configuration needed</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: violet}}/>
                                                    <p>White labeling functionality</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: violet}}/>
                                                    <p>User interface (brand, color scheme, …)</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: violet}}/>
                                                    <p>Reports</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: violet}}/>
                                                    <p>Enforcement module</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: violet}}/>
                                                    <p>User training</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: violet}}/>
                                                    <p>Access to a dedicated support team</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: violet}}/>
                                                    <p>Real time data processing & AI-based analysis</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </section>
                    </div>

                    {/* "Subscriptions" section */}
                    <div>
                        <header className="section-header-little-margin" data-aos="fade-up">
                            <p> Overview </p>
                            <h2 style={{color:violet}}> Subscriptions </h2>
                        </header>

                        <section className="magnify" >
                            <div className="container" data-aos="fade-up">
                            
                                <p className='text-center'> A new way to manage and protect brands without wasting time and resources; but most importantly <span style={{color: violet}}>making it efficient and cost effective.</span></p>
                                <br/>

                                {/* TODO: later try to extract grid-item-checkbox and grid-item-item to a component */}
                                <div className="row gy-5">
                                    <div className="aos-init aos-animate col-lg-4 col-md-6">
                                        <div className="box">
                                            <h2>Starter's Program</h2>
                                            <div className="grid-container">
                                                <div className="grid-item-checkbox"><input type="checkbox" checked readOnly/></div>
                                                <div className="grid-item-text">Domain names</div>
                                                <div className="grid-item-checkbox"><input type="checkbox" checked readOnly/></div>
                                                <div className="grid-item-text">Top 10 marketplaces/social media platforms</div>
                                                <div className="grid-item-checkbox"><input type="checkbox" checked readOnly/></div>
                                                <div className="grid-item-text">1 add-on</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="aos-init aos-animate col-lg-4 col-md-6">
                                        <div className="box">
                                            <span className="featured">Most popular</span>
                                            <h2>Basic Program</h2>
                                            <div className="grid-container">
                                                <div className="grid-item-checkbox"><input type="checkbox" checked readOnly/></div>
                                                <div  className="grid-item-text">Domain names</div>
                                                <div className="grid-item-checkbox"><input type="checkbox" checked readOnly/></div>
                                                <div  className="grid-item-text">Up to 50 marketplaces/social media platforms</div>
                                                <div className="grid-item-checkbox"><input type="checkbox" checked readOnly/></div>
                                                <div  className="grid-item-text">Up to 2 add-ons</div>
                                                <div className="grid-item-checkbox"><input type="checkbox" checked readOnly/></div>
                                                <div  className="grid-item-text">Logo search</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="aos-init aos-animate col-lg-4 col-md-6">
                                        <div className="box">
                                            <h2>Advanced Program</h2>
                                            <div className="grid-container">
                                                <div className="grid-item-checkbox"><input type="checkbox" checked readOnly/></div>
                                                <div  className="grid-item-text">Domain names</div>
                                                <div className="grid-item-checkbox"><input type="checkbox" checked readOnly/></div>
                                                <div  className="grid-item-text">Up to 150 marketplaces/social media platforms</div>
                                                <div className="grid-item-checkbox"><input type="checkbox" checked readOnly/></div>
                                                <div  className="grid-item-text">Up to 3 add-ons</div>
                                                <div className="grid-item-checkbox"><input type="checkbox" checked readOnly/></div>
                                                <div  className="grid-item-text">Logo search</div>
                                                <div className="grid-item-checkbox"><input type="checkbox" checked readOnly/></div>
                                                <div  className="grid-item-text">Review sites</div>
                                                <div className="grid-item-checkbox"><input type="checkbox" checked readOnly/></div>
                                                <div  className="grid-item-text">App stores</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="text-center">
                                    <a href="/home#contacts" className="magnify-btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                                        <span> Request your demo </span>
                                        <i className="bi bi-arrow-right"></i>
                                    </a>
                                    <br /><br />
                                    <p><b>It's free, no obligation.</b></p>
                                </div>

                            </div>
                        </section>

                    </div>

                    <BackToTopButton />

                </main>
            </BasicLayout>
        </ScrollToTop>
    );
}