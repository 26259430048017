import { useTranslation } from 'react-i18next';
import React from 'react';

export default (props) => {
    const { t, i18n } = useTranslation();

    const [showLoadingMessage, setShowLoadingMessage] = React.useState('none')
    const [showErrorMessage, setShowErrorMessage] = React.useState('none')
    const [showSentMessage, setShowSentMessage] = React.useState('none')

    const handleSubmit = async (e) => {
        e.preventDefault()

        const form = document.getElementById('contact_form')

        setShowLoadingMessage('block')
        
        //TODO: this link has to change for production
        const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/server/form`, {
            method: 'POST',
            body: JSON.stringify({
                name: form.name.value,
                email: form.email.value,
                subject: form.subject.value,
                message: form.message.value
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).catch(error => {
            console.log(`Entered catch with error: ${error}`)
            setShowLoadingMessage('none')
            setShowErrorMessage('block')
        })

        if(res === undefined) return;
        
        const data = await res.json()        
        //console.log('Server response:')
        //console.log(data)
        
        setShowLoadingMessage('none')
        if(data.sucess) setShowSentMessage('block')
        else setShowErrorMessage('block')
        
    };

    //TODO: change action="mailto:" email to the correct one for production
    return(
        <div className="col-lg-6">
            <form id='contact_form' onSubmit={handleSubmit} method="POST" className="php-email-form">
                <div className="row gy-4">
                    <div className="col-md-6">
                        <input type="text" name="name" className="form-control" placeholder="Your Name" required />
                    </div>

                    <div className="col-md-6 ">
                        <input type="email" className="form-control" name="email" placeholder="Your Email" required />
                    </div>

                    <div className="col-md-12">
                        <input type="text" className="form-control" name="subject" placeholder="Subject" required />
                    </div>

                    <div className="col-md-12">
                        <textarea className="form-control" name="message" rows="6" placeholder="If you need more info, want to join the team or tell us more about your project, send us a message!" required></textarea>
                    </div>

                    <div className="col-md-12 text-center">
                        <div className="loading" style={{display: showLoadingMessage}}>Loading</div>
                        <div className="error-message" style={{display: showErrorMessage}}>Error while sending your message.</div>
                        <div className="sent-message" style={{display: showSentMessage}}>Your message has been sent. Thank you!</div>
                        <button type="submit">Send Message</button>
                    </div>
                </div>
            </form>
        </div>
    );
}