import BasicLayout from "../layouts/BasicLayout"
import BreadCrumbs from "../components/common/BreadCrumbs"
import ScrollToTop from "../components/common/ScrollToTop"
import BackToTopButton from "../components/common/BackToTopButton";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default () => {
    const { t } = useTranslation();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/assets/js/main.js";
        document.body.appendChild(script);
    }, []);

    return (
        <ScrollToTop>
            <BasicLayout>
                <main>
                    <BreadCrumbs path={t('partners')} title={t('partners')} />
                    <section id="faq" className="faq">
                        <div className="container" data-aos="fade-up">
                            <header className="section-header-little-margin">
                                <p> {t('partners')} </p>
                                <h2> {t('partners_h2')} </h2>
                            </header>

                            <div className="text-center">
                                <p> {t('partners_p1')} </p>
                                <p> {t('partners_p2')} </p>
                                <br />
                            </div>

                            <div className="accordion accordion-flush" id="faqlist1">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-1">
                                            <div className="col-md-2" data-aos="fade-center" data-aos-delay="200">
                                                <img src="assets/img/partners/planview-logo.png" className="img-fluid" alt="Collaboration" />
                                            </div>
                                            <div className="col-md-9 text-center">
                                                {t('partners_planview_title')} 
                                            </div>
                                        </button>
                                    </h2>
                                    
                                    <div id="faq-content-1" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                        <div className="accordion-body accordion-body-padding">
                                            <p> {t('partners_planview_p1')} </p>
                                            <p> {t('partners_planview_p2')} </p>
                                            <p> {t('partners_planview_p3')} </p>
                                            <p className="accordion-text-right">
                                                <a  href='https://www.planview.com' target="_blank">{t('partners_planview_link')} </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-2">
                                            <div className="col-md-2" data-aos="fade-right" data-aos-delay="200">
                                                <img src="assets/img/partners/p3m-logo.png" className="img-fluid" alt="International" />
                                            </div>
                                            <div className="col-md-9 text-center">
                                                {t('partners_p3m_title')} 
                                            </div>
                                        </button>
                                    </h2>
                                    <div id="faq-content-2" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                        <div className="accordion-body accordion-body-padding">
                                            <p> {t('partners_p3m_p1')} </p>
                                            <p> {t('partners_p3m_p2')} </p>
                                            <p className="accordion-text-right">
                                                <a  href='https://www.p3mpartners.com' target="_blank"> {t('partners_p3m_link')} </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-3">
                                            <div className="col-md-2" data-aos="fade-right" data-aos-delay="200">
                                                <img src="assets/img/partners/moveo-logo.png" className="img-fluid" alt="Remotely Close" />
                                            </div>
                                            <div className="col-md-9 text-center">
                                                {t('partners_moveo_title')} 
                                            </div>
                                        </button>
                                    </h2>
                                    <div id="faq-content-3" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                        <div className="accordion-body accordion-body-padding">
                                            <p> {t('partners_moveo_p1')} </p>
                                            <p> {t('partners_moveo_p2')} </p>
                                            <p> {t('partners_moveo_p3')} </p>
                                            <p className="accordion-text-right">
                                                <a  href='https://www.moveo.it/' target="_blank"> {t('partners_moveo_link')} </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-4">
                                            <div className="col-md-2" data-aos="fade-right" data-aos-delay="200">
                                                <img src="assets/img/clients/client-1.png" className="img-fluid" alt="IPMetro" />
                                            </div>
                                            <div className="col-md-9 text-center">
                                                {t('partners_ipmetro_title')} 
                                            </div>
                                        </button>
                                    </h2>
                                    <div id="faq-content-4" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                        <div className="accordion-body accordion-body-padding">
                                            <p> {t('partners_ipmetro_p1')} </p>
                                            <p> {t('partners_ipmetro_p2')} </p>
                                            <p className="accordion-text-right">
                                                <a  href='https://www.ipmetro.com/' target="_blank"> {t('partners_ipmetro_link')} </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <BackToTopButton />

                </main>
            </BasicLayout>
        </ScrollToTop>
    );
}