import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

/**
 * To add collaborators just add a new object to the collaborators.json file on server-side (backend/data) 
 * and an image of the new collaborator to the public/assets/img/team folder.
 * To remove, just need to delete the collaborator from the json file and the image from the folder.
 */
export default (props) => {
    const { t, i18n } = useTranslation();
    const [collaborators, setCollaborators] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/server/collaborators`, 
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(results => results.json())
            .then(data => {
                setCollaborators(data)
            })
    }, []);
    
    return(
        <section id="team" className="team">
            <div className="container" data-aos="fade-up">
                <header className="section-header-little-margin">
                    <p>{t('about_team_p')}</p>
                    <h2>{t('about_team_h2')}</h2>
                </header>

                <div className="row gy-4" style={{marginBottom: '50px'}}>
                    {collaborators.map( collaborator => { 
                        return <TeamCard 
                            imageName = {collaborator.imageName}
                            linkedInUrl = {collaborator.linkedInUrl}
                            name = {collaborator.name}
                            position = {collaborator.position}
                            description = {collaborator.description}
                        /> 
                    })}
                </div>
            </div>
        </section>
    );
}

/**
 * This component represents the team member card.
 * It receives as props:
 * > imageName - the name of the image (PS.: add the collaborator's image to assets/img/team folder, please)
 * > linkedInUrl - collaborator's LinkdeIn link
 * > name - collaborator's name
 * > position - collaborator's role in the company
 * > description - collaborator's small description
 */
 const TeamCard = (props) => {
    const imageUrl = props.imageName != "" ? "assets/img/team/" + props.imageName : "assets/img/team/unknown.jpg"
    
    return(
        <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
            <div className="member">
                <div className="member-img">
                    <img hidden src={imageUrl} className="img-fluid" alt="Member of HBIT-Consulting" />
                    <div className="social">
                        <a href={props.linkedInUrl}><i className="bi bi-linkedin"></i></a>
                    </div>
                </div>
                <div className="member-info">
                    <h4> {props.name} </h4>
                    <span> {props.position} </span>
                    <p> {props.description} </p>
                </div>
            </div>
        </div>
    )
}