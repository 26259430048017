import { useTranslation } from 'react-i18next';
import Distinghishes from './Distinghishes';

export default (props) => {
    const { t, i18n } = useTranslation();
    
    return(
        <section id="features" className="features">
            <div className="container" data-aos="fade-up">
                <header className="section-header-little-margin">
                    <p> {t('more_about_us')} </p>
                    <h2> {t('about_details_h2')} </h2>
                </header>

                <div className="row gy-5">
                    <div className="col-lg-4 col-md-6 justify-content-center d-flex">
                        <div className='about_us_section'>
                            <div className="square" style={{backgroundColor:"#E8AC14"}}/>
                            <h4>Values</h4>
                            <div className="col-md-11" style={{margin:'auto', height:"80%"}}>
                                <div className="card border-color" style={{maxWidth: "30rem", borderColor:"#E8AC14", height:"100%"}}>
                                    <div className="card-body">
                                        <p>We put <b>the human collaboration</b> as high priority in all actions we take. </p>
                                        <p>Our objectives and actions are always <b>customer focused</b>. </p>
                                    </div>
                                    <div className="triangle" style={{borderColor: "#E8AC14 transparent"}}></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 justify-content-center d-flex">
                        <div className='about_us_section'>
                            <div className="square" style={{backgroundColor:"#A52020"}}/>
                            <h4>Mission</h4>
                            <div className="col-md-11" style={{margin:'auto', height:"80%"}}>
                                <div className="card border-color" style={{maxWidth: "30rem", borderColor:"#A52020", height:"100%"}}>
                                    <div className="card-body">
                                        <p>We are dedicated to <b>support our customers</b> in order to unify their business and IT in the best way.</p>
                                        <p>To make things coherent and as simple as possible thanks to our <b>large scale of functional and technical skills</b>.</p>
                                    </div>
                                    <div className="triangle" style={{borderColor: "#A52020 transparent"}}></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 justify-content-center d-flex">
                        <div className='about_us_section'>
                            <div className="square" style={{backgroundColor:"#3C3535"}}/>
                            <h4>Approach</h4>
                            <div className="col-md-11" style={{margin:'auto', height:"80%"}}>
                                <div className="card border-color" style={{maxWidth: "30rem", borderColor:"#3C3535", height:"100%"}}>
                                    <div className="card-body">
                                        <p>We are strongly committed to provide the <b>best collaboration</b> with our <b>highly skilled</b> professionals. </p>
                                        <p>We believe that delivering high quality services is the best way to find new opportunities: <b>Our best sellers are our Customers!</b></p>
                                    </div>
                                    <div className="triangle" style={{borderColor: "#3C3535 transparent"}}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                

                <div className="row">

                    <div className="col-lg-6 mt-5 mt-lg-0 d-flex">
                        <div className="col align-self-center gy-4">
                            <header className="section-header-little-margin">
                                <p> Nearshore </p>
                                <h2> Services in Portuguese, French and English </h2>
                            </header>
                            
                            <div className="col-md-6" data-aos="zoom-out" data-aos-delay="200" style={{backgroundColor:'#A52020', marginLeft:'auto', marginRight:'auto', marginBottom:'15px'}}>
                                <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check"></i>
                                    <h3>OFFICES: <br/>France - Marseille & Perpignan<br/>Portugal - Lisbon<br/>United Kingdom: Gloucestershire</h3>
                                </div>
                            </div>

                            <div className="col-md-6" data-aos="zoom-out" data-aos-delay="300" style={{backgroundColor:'#E8AC14', marginLeft:'auto', marginRight:'auto', marginBottom:'15px'}}>
                                <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check"></i>
                                    <h3>PARTNERS:<br/>United Kingdom - P3M<br/>Italy: Moveo<br/>Worldwide: Planview</h3>
                                </div>
                            </div>

                            <div className="col-md-6" data-aos="zoom-out" data-aos-delay="400" style={{backgroundColor:'#3C3535', marginLeft:'auto', marginRight:'auto', marginBottom:'15px'}}>
                                <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check"></i>
                                    <h3>Customers from:<br/>France, Portugal, UK, Italy, Angola, Belgium & Germany</h3>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-lg-6 col align-self-center gy-4 mt-5" style={{verticalAlign: 'middle'}}>
                        <img src="assets/img/MAPS-HBIT2.png" className="img-fluid img_round2" alt="" style={{marginTop:'', justifyContent: 'center', verticalAlign: 'middle'}}/>
                    </div>

                </div>
    
    
                {/* */}
                <div className="row feature-icons faq" data-aos="fade-up" style={{ marginTop:'10px' }}>
                    <header className="section-header-little-margin">
                        <p> {t('about_h1')} </p>
                        <h2> What Distinguishes Us </h2>
                    </header>

                    <p style={{textAlign:'center'}}>
                        We have more than 10 years of experience and we are characterized by the following concepts:
                    </p>

                    <div className="row">
                        <div className="col-xl-6 md-6 lg-6 text-center align-items-center" data-aos="fade-right" data-aos-delay="100">
                            <img src="assets/img/+10_v2.png" className="img-fluid p-4" alt="" />
                        </div>

                        <div className="col-xl-6 md-6 lg-6">
                            <Distinghishes />
                        </div>         
                    </div>
                </div>
     
            </div>
        </section>
    );
}