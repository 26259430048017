import BasicLayout from "../layouts/BasicLayout"
import BreadCrumbs from "../components/common/BreadCrumbs"
import ScrollToTop from "../components/common/ScrollToTop"
import BackToTopButton from "../components/common/BackToTopButton";
import { useEffect } from 'react';
import React from 'react'
import { Navigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { List, ListItem, Divider, ListItemText, ListItemAvatar, Avatar, Typography } from '@mui/material';


/**
 *  Component created using Material UI package.
 *  Website: https://mui.com/
 */
export default ({children}) => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/assets/js/main.js";
        document.body.appendChild(script);
    }, []);

    const user = localStorage.getItem('loginData')

    return (
        <div>
        {
            user ? (
                <ScrollToTop>
                    <BasicLayout>
                        <main>
                            <BreadCrumbs path='Resources' title='Company Resources'/>

                            <section id="resources">
                                <div className="container" data-aos="fade-up">
                                    <header className="section-header-little-margin">
                                        <p> Useful Resources </p>
                                        <h2> Here you can find company's resources </h2>
                                    </header>

                                    {/*
                                    *   Used this template: https://codesandbox.io/s/tp2l07?file=/demo.js
                                    *   Used the <List> and <ListItem> components like a normal <ul> and <li> list but,
                                    *   by using MUI, we have already a more styled and responsive list.
                                    *       . <ListItemAvatar> component is the image in the left of each list's entry.
                                    *       . <ListItemText> is the component for the text an receives props:
                                    *           .@primary - the main content element, in this caso used for the title of the item
                                    *           .@secondary - the secondary content element, in this case passed a fragment with
                                    *                       the <Typography> element to insert the description and change the style of 
                                    *                       the text.
                                    * 
                                    *   Documentation used: 
                                    *   https://mui.com/material-ui/react-list/#api
                                    *   https://mui.com/material-ui/api/list-item-text/
                                    *   https://mui.com/material-ui/api/typography/
                                    */}
                                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                        <ListItem alignItems="flex-start" button component="a" href="https://wiki.hb-itconsulting.pt/" target="_blank" >
                                            <ListItemAvatar>
                                                <Avatar alt="Wiki" src="/assets/img/resources/wiki.jpeg" />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Wiki"
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography
                                                            sx={{ display: 'inline'}}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                            The Knowledge@Hb-IT Consulting displays knowledge articles related to the company and the projects organized by knowledge base and category.
                                                        </Typography>
                                                        
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>

                                        <Divider variant="inset" component="li" />

                                        <ListItem alignItems="flex-start" button component="a" href="https://pass.hb-itconsulting.pt/index.html#!/"  target="_blank" >
                                            <ListItemAvatar>
                                                <Avatar alt="psono" src="/assets/img/resources/psono.png" />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Psono"
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography
                                                            sx={{ display: 'inline' }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                            Password manager tool used in HB-IT Consulting.
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>

                                        <Divider variant="inset" component="li" />

                                        <ListItem alignItems="flex-start" button component="a" href="https://hb-itconsulting.atlassian.net/jira/your-work" target="_blank" >
                                            <ListItemAvatar>
                                                <Avatar alt="jira" src="/assets/img/resources/jira.png" />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Jira"
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography
                                                            sx={{ display: 'inline' }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                            Agile development tool used by HB-IT Consulting to track issues and do the project management.
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>

                                        <Divider variant="inset" component="li" />

                                        <ListItem alignItems="flex-start" button component="a" href="https://gitlab.com/" target="_blank" >
                                            <ListItemAvatar>
                                                <Avatar alt="psono" src="/assets/img/resources/gitlab.jpeg" />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="GitLab"
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography
                                                            sx={{ display: 'inline' }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                            DevOps Tool used for the software development of the company's projects.
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>

                                        <Divider variant="inset" component="li" />

                                        <ListItem alignItems="flex-start" button component="a" href="http://ppm.hb-itconsulting.pt/" target="_blank" >
                                            <ListItemAvatar>
                                                <Avatar alt="Ppm" src="/assets/img/resources/project-open.png" />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="PPM"
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography
                                                            sx={{ display: 'inline' }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                            This tools enables collaborators and administrators to keep track of working time.
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>

                                        <Divider variant="inset" component="li" />

                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar alt="gmail" src="/assets/img/resources/gmail.png" />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Email"
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography
                                                            sx={{ display: 'inline' }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                            This is the HB-IT Consulting's general email: info@hb-itconsulting.pt .
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>

                                        <Divider variant="inset" component="li" />

                                        <ListItem alignItems="flex-start" button component="a" href="https://app.close.com/" target="_blank" >
                                            <ListItemAvatar>
                                                <Avatar alt="close" src="/assets/img/resources/close.png" />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="CRM Tool"
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography
                                                            sx={{ display: 'inline' }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                            This is the HB-IT Consulting's new CRM tool.
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>

                                        <Divider variant="inset" component="li" />

                                        <ListItem alignItems="flex-start" button component="a" href="https://app.useace.com/6839/meetings/dashboard" target="_blank" >
                                            <ListItemAvatar>
                                                <Avatar alt="close" src="/assets/img/resources/ace-logo.jpeg" />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Meetings Tool"
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography
                                                            sx={{ display: 'inline' }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                            This is the HB-IT Consulting's new meetings tool.
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                    </List>
                                </div>
                            </section>

                            <BackToTopButton />

                        </main>
                    </BasicLayout>
                </ScrollToTop>
            ) :
            (
                <Navigate to='/home' replace />
            )
        }
        </div>                                 
    );
}
