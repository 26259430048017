import BasicLayout from "../layouts/BasicLayout"
import BreadCrumbs from "../components/common/BreadCrumbs"
import ScrollToTop from "../components/common/ScrollToTop"
import BackToTopButton from "../components/common/BackToTopButton";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default (props) => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/assets/js/main.js";
        document.body.appendChild(script);
    }, []);

    const color = "#A52020"
    const fontSize = 22

    return (
        <ScrollToTop>
            <BasicLayout>
                <main>
                    <BreadCrumbs path='Terms' title='Terms of Service' />
                    <div>
                        <header className="section-header-little-margin">
                            <p> {t('footer_terms')} </p>
                            <h2> Here you can find our's terms of service </h2>
                        </header>

                        <section className="about">
                            <div className="container" data-aos="fade-up">
                                <ol>
                                    <li style={{color:color, fontSize:fontSize}}> {t('terms_li1')} </li>
                                    <p>{t('terms_p1')}</p>

                                    <li style={{color:color, fontSize:fontSize}}> {t('terms_li2')} </li>
                                    <p>{t('terms_p2')}</p>

                                    <li style={{color:color, fontSize:fontSize}}> {t('terms_li3')} </li>
                                    <p>{t('terms_p3')}</p>

                                    <li style={{color:color, fontSize:fontSize}}> {t('terms_li4')} </li>
                                    <p>{t('terms_p4')}</p>

                                    <li style={{color:color, fontSize:fontSize}}> {t('terms_li5')} </li>
                                    <p>{t('terms_p5')}</p>

                                    <li style={{color:color, fontSize:fontSize}}> {t('terms_li6')} </li>
                                    <p>{t('terms_p6')}</p>

                                    <li style={{color:color, fontSize:fontSize}}> {t('terms_li7')} </li>
                                    <p>{t('terms_p7')}</p>

                                    <li style={{color:color, fontSize:fontSize}}> {t('terms_li8')} </li>
                                    <p>{t('terms_p8')}</p>
                                </ol>
                            </div>
                        </section>

                    </div>
                    
                    <BackToTopButton />
                    
                </main>
            </BasicLayout>
        </ScrollToTop>
    );
}
