import { useTranslation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';
import { useState, useEffect } from 'react';

/**
 * To add success cases just add a new object to the successCases.json file on server-side (backend/data) 
 * and an image of the company to the public/assets/img/clients folder.
 */
export default (props) => {
    const { t, i18n } = useTranslation();
    const [successCases, setSuccessCases] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/server/successCases`, 
            {
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
            }
        )
            .then(results => results.json())
            .then(data => setSuccessCases(data) )
    }, []);
    
    return(
        <section id="successCases" className="solutions">
            <div className="container" data-aos="fade-up">
                <header className="section-header">
                    <p>{t('Success Cases')}</p>
                    <h2>{t('See how HB-IT Consulting is helping customers')}</h2>
                </header>

                {/* Success Cases' first row */}
                <div className="row" style={{display:'flex', marginBottom:'25px'}}>
                    {
                        successCases.map((successCase) => {
                            return <div className="col-lg-4 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="300" style={{padding:'1.5em'}}>
                                <div className="box">
                                    <h3 style={{color: successCase.color}}>{successCase.companyName.toUpperCase()}</h3>
                                    <ul>
                                        <li>{successCase.service.toUpperCase()}</li>
                                    </ul>
                                    <img src={successCase.smallLogoPath} className="img-fluid" style={{maxWidth:'320px', objectFit: 'cover'}} alt={successCase.companyName + " logo"} />
                                    <div style={{paddingBottom:'25px'}}>
                                        <p>{successCase.smallDescription}</p>
                                    </div>
                                    <Link 
                                        to={`/successCases?company=${successCase.id}`}
                                        state={successCase}
                                        className="read_more_button position-absolute bottom-0 start-50 translate-middle" 
                                        style={{backgroundColor: successCase.color}}
                                    >
                                        <span> {t('read_more')} </span>
                                        <i className="bi bi-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </section>
    );
}