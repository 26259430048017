import BasicLayout from "../../layouts/BasicLayout"
import BreadCrumbs from "../../components/common/BreadCrumbs"
import ScrollToTop from "../../components/common/ScrollToTop";
import BackToTopButton from "../../components/common/BackToTopButton";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default ({children}) => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/assets/js/main.js";
        document.body.appendChild(script);
    }, []);

    const gray = '#3C3535'

    return (
        <ScrollToTop>
            <BasicLayout>
                <main>
                    <BreadCrumbs path='Solutions' title='Planview Barometer IT' />
                    <div>
                        <header className="section-header-little-margin" data-aos="fade-up">
                            <p> Planview Barometer Integrated Portfolio Management software </p>
                            <h2> Align your portfolios to deliver strategic change </h2>
                        </header>
                        
                        <section className="about">
                            <div className="container" >

                                <div className="row gx-0">
                                    <div className="col-lg-6 content d-flex align-items-center" data-aos="fade-right" data-aos-delay="200" style={{backgroundColor:'#3C3535'}}>
                                        <p>Planview Barometer (formerly BarometerIT) enables business and IT leaders to assess and prioritize enterprise projects and portfolios to support strategic decision-making across the organization. <br/> <br/>
                                        SaaS-based solution, offers integrated portfolio analysis that enables continuous alignment of strategic objectives and operational initiatives. Its unparalleled mapping capabilities deliver real-time views and on-demand executive context on business health, risks, redundancies and investment performance against objectives.  </p>
                                    </div>

                                    <div className="col-lg-6 d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="300" style={{backgroundColor:'#A52020', padding:'75px'}}>
                                        <img className='img-fluid' src="assets/img/solutions/barometer_white.png" alt="planview barometer"/>
                                    </div>
                                </div>
                                <br />
                                <p style={{textAlign: 'center'}} data-aos="fade-up">
                                    For more information, please visit the official page: <a href="https://www.planview.com/products-solutions/products/barometer/" target="_blank"> Here!</a>
                                </p>
                                
                            </div>
                        </section>
                    </div>

                    {/* Colummns with text for barometer */}
                    <div style={{marginTop: '50px'}}>
                        <section className="collumns" >
                            <div className="container">

                                <div className="row">
                                    <div className="col-lg-3">
                                    </div>
                                    <div className="col-lg-6 mt-5 mt-lg-0 d-flex align-self-center" data-aos="fade-up" data-aos-delay="100">
                                        <div className="col align-self-center gy-4">   
                                            <h1 className="d-flex justify-content-center">Strategic Planning</h1> 
                                            <p>Map initiatives to business strategies at every step in the planning process, identifying potential impacts and risks.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-3 mt-5 mt-lg-0 d-flex align-self-center" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4">   
                                            <h1 className="d-flex justify-content-center">Improve Enterprise Agility</h1> 
                                            <p>Make better decisions faster by understanding the relationships between projects, capabilities, and applications.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mt-5 mt-lg-0 d-flex align-items-center justify-content-center" data-aos="zoom-out" data-aos-delay="300" style={{marginBottom: '1rem'}}>
                                        <img src="assets/img/solutions/barometer/barometer_dashboard.png" className="img-fluid img_2round2 w-100" alt="Barometer dashboard"/>
                                    </div>
                                    <div className="col-lg-3 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="100">
                                        <div className="col align-self-center gy-4">   
                                            <h1 className="d-flex justify-content-center">Insights</h1> 
                                            <p>Collect, visualize, and understand inventory, capabilities, interdependencies, and more with accurate data from across the enterprise.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-3">
                                    </div>
                                    <div className="col-lg-6 mt-5 mt-lg-0 d-flex align-self-center" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4">   
                                            <h1 className="d-flex justify-content-center">Advanced and intuitive features such as:</h1> 
                                            <div className="tab-content align-middle">
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"/>
                                                    <p>Kanban-style Portfolio Prioritization</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"/>
                                                    <p>Portfolio Visualization</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2"/>
                                                    <p>Scenario Planning</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" />
                                                    <p>Portfolio Analysis</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                    </div>
                                </div>

                                

                            </div>
                        </section>
                    </div>

                    {/* "What are our differentiators" section */}    
                    <div>
                        <header className="section-header-little-margin" data-aos="fade-up">
                            <p> barometerIT  </p>
                            <h2> What are our differentiators? </h2>
                        </header>
                        
                        <section className="collumns">
                            <div className="container">

                                <div className="row">
                                    <div className="col-lg-6 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4">   
                                            <h1>Simple</h1> 
                                            <p> Simple, intuitive and easy to master.  Anyone can start contributing value in minutes. </p>
                                            <br />

                                            <h1>Smart</h1>
                                            <div className="tab-content align-middle">
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" />
                                                    <p>Strategic alignment and planning</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" />
                                                    <p>Value based portfolio analysis</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" />
                                                    <p>Interactive impact analysis</p>
                                                </div>
                                            </div> 
                                            <br />

                                            <h1> Social </h1> 
                                            <p> The game changer. Delivers a level of transparency, discussion, excitement and engagement unseen by traditional approaches. </p>

                                        </div>
                                    </div>

                                    <div className="col-lg-6 d-flex align-self-center justify-content-center" data-aos="zoom-out" data-aos-delay="100">
                                        <img src="assets/img/solutions/barometer/barometer_connections.png" className="img-fluid" alt=""/>
                                    </div>
                                </div>

                            </div>
                        </section>
                    </div>

                    {/* "Crowdsourcing brings insight to you business" section */}
                    <div>
                        <header className="section-header-little-margin" data-aos="fade-up">
                            <p> barometerIT Improves Business Agility </p>
                            <h2> Crowdsourcing brings insight to you business </h2>
                        </header>   

                        <section className="collumns">
                            <div className="container" data-aos="fade-up">

                                <div className="row">
                                     <div className="col-lg-6 d-flex align-self-center justify-content-center" data-aos="zoom-out" data-aos-delay="100">
                                        <img src="assets/img/solutions/barometer/barometer_crowdsourcing.png" className="img-fluid" alt="barometerIT Crowdsourcing"/>
                                    </div>

                                    <div className="col-lg-6 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4">   
                                            <div className="tab-content align-middle">
                                                <h1>barometerIT Enterprise Crowdsourcing</h1> 
                                                <p> Enterprise users crowdsource information, capturing and organizing data about strategies, systems, technologies, profiles, and capabilities, to quickly create a live enterprise map. </p>
                                                <br />
                                                <p> While large quantities of data can often be cumbersome and unusable, business and technology leaders using barometerIT are able to assess relationships and dependencies in the information easily. </p>
                                                <br />
                                                <p> They build insights that help them adjust strategies and approaches immediately, to better meet business objectives. As systems and processes evolve and programs conclude, stakeholders analyze outcomes, and update the enterprise map. </p>
                                                <br />
                                                <p> This helps them optimize business outcomes in new initiatives. </p>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </section>
                    </div>

                    <BackToTopButton />
                    
                </main>
            </BasicLayout>
        </ScrollToTop>
    );
}