import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

export default (props) => {
    const { t, i18n } = useTranslation();
    const [counters, setCounters] = useState([]);
    
    const scripts = [
        "/assets/vendor/purecounter/purecounter.js",
        "/assets/js/main.js",
    ]
    
    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/server/counters`, 
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(results => results.json())
            .then(data => {
                setCounters(data)

                // Loading the js files to support counters animation. Looks like we need to have the values before
                //these imports occur 
                scripts.map((path) => {
                    const script = document.createElement('script');
                    script.src = path;
                    document.body.appendChild(script);
                    return script 
                })
            })
    }, []);
      
    return(
        <section id="counts" className="counts">
            <div className="container" data-aos="fade-up">
                <div className="row gy-4">
                    {counters.map( counter => {
                        return <Counter 
                            symbolName = {counter.symbolName}
                            color = {counter.color}
                            counterStart = {counter.counterStart}
                            counterEnd = {counter.counterEnd}
                            counterDuration = {counter.counterDuration}
                            text = {counter.text}
                        />
                    })}
                </div>
            </div>
        </section>
    );
}

/**
 * Needs to receive props with the info needed to create a counter card
 * @param {*} props:
 * > className - class name of the symbol
 * > color - the color of the symbol
 * > conterStart - the number where the counter will start the animation
 * > counterEnd - the number that we want to show (where the animation will end)
 * > counterDuration - the duration time for the animation
 * > text - the text that will be shown
 * @returns a card with the counter
 */
const Counter = (props) => {
    const className = "bi bi-" + props.symbolName

    return(
        <div className="col-lg-3 col-md-6">
            <div className="count-box">
                <i className={className} style={{color: props.color}}></i>
                <div>
                    <span 
                        data-purecounter-start={props.counterStart} 
                        data-purecounter-end={props.counterEnd} 
                        data-purecounter-duration={props.counterDuration} 
                        className="purecounter" 
                    />
                    <p> {props.text} </p>
                </div>
            </div>
        </div>
    )
}