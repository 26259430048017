import NavBar from '../components/common/NavBar'
import Footer from '../components/common/Footer'

const companyName = 'HB IT Consulting'

export default ({children}) => {
    return(
        <div>
            <NavBar title={companyName}></NavBar>
                {children}
            <Footer title={companyName} ></Footer>
        </div>
    )
}