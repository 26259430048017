import { useEffect } from "react";
import { useLocation } from "react-router";

/*
*   Higher order function so, when user goes to another page, the window is rendered on top of the page.
*   Didn't use it on index.js because home page is SPA an the scroll would go to the top of the page
*   and then would go to the correct place if user went to home from another page.
*/
const ScrollToTop = (props) => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return <>{props.children}</>
};

export default ScrollToTop;