import BasicLayout from "../../layouts/BasicLayout"
import BreadCrumbs from "../../components/common/BreadCrumbs"
import ScrollToTop from "../../components/common/ScrollToTop";
import BackToTopButton from "../../components/common/BackToTopButton";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default ({children}) => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/assets/js/main.js";
        document.body.appendChild(script);
    }, []);

    return (
        <ScrollToTop>
            <BasicLayout>
                <main>
                    <BreadCrumbs path='Consulting' title='Consulting Services' />
                    <div>
                        <section id="consulting_page" className="consulting_page">
                            <div className="container" data-aos="fade-up">
                                <header className="section-header-little-margin">
                                    <p> Consulting Services </p>
                                    <h2> Services to Drive Your Projects Forward </h2>
                                </header>

                                {/* "Consulting Services" section */}
                                <div>
                                    <p>We provide you with the <b>best suited solution experts</b> for your <b>regional and global business</b>. Our office heads are happy to discuss how we can support you <b>to secure long-term competitive advantage</b> and transform your business in the age of digitalization.</p>
                                    <p>Some of our competences are:</p>
                                    <br/>

                                    <div className="row row-cols-1 row-cols- row-cols-md-4 g-4">
                                        
                                        <div className="col">
                                            <div className="card h-100 border-end">
                                                <div className="card-body">
                                                    <h3 className="card-title" style={{color:'#A52020', marginBottom:'20px', textAlign:'center'}}>Project Management</h3>
                                                    <p className="card-text" style={{textAlign:'center'}}>Helping organizations achieve project goals and objectives within scope, time, and budgetary constraints.</p>
                                                    <br/>
                                                    <br/>
                                                    <br/>
                                                    <img src="assets/img/services/services_1.jpeg" className="img-fluid card-img-bottom img_round2" alt="management" style={{width: "100%", height:"14vw", objectFit:"cover"}}/>
                                                </div>
                                                
                                            </div>
                                        </div>

                                        <div className="col">
                                            <div className="card h-100 border-end">
                                                <div className="card-body">
                                                    <h3 className="card-title" style={{color:'#A52020', marginBottom:'20px', textAlign:'center'}}>Solution Architecture</h3>
                                                    <p className="card-text" style={{textAlign:'center'}}>Our Solution Architecture Services help customers to understand existing technology systems and plot out a realistic path toward higher enterprise efficiency, easier compliance and smarter governance.</p>
                                                    <img src="assets/img/services/services_2.jpeg" className="card-img-bottom img_round2" alt="management" style={{width: "100%", height:"14vw", objectFit:"cover"}}/>
                                                </div>
                                                
                                            </div>
                                        </div>

                                        <div className="col">
                                            <div className="card h-100 border-end">
                                            
                                                <div className="card-body">
                                                    <h3 className="card-title" style={{color:'#A52020', marginBottom:'20px', textAlign:'center'}}>IT Solution<br/> Consulting</h3>
                                                    <p className="card-text" style={{textAlign:'center'}}>Partner with our clients to understand their challenges within the context of their business and user base along a singular focus: making sure they are successful.</p>
                                                    <img src="assets/img/services/services_3.jpeg" className="card-img-bottom img_round2" alt="management" style={{width: "100%", height:"14vw", objectFit:"cover"}}/>
                                            
                                                </div>
                                                
                                            </div>
                                        </div>

                                        <div className="col">
                                            <div className="card h-100 border-end">
                                            
                                                <div className="card-body">
                                                    <h3 className="card-title" style={{color:'#A52020', marginBottom:'20px', textAlign:'center'}}>Technical Consulting</h3>
                                                    <p className="card-text" style={{textAlign:'center'}}>How can technology add value to your business? In co-creation, our technical experts help you to see the bigger picture. They lead you through a transformation that really benefits you.</p>
                                                    <br/>
                                                    <img src="assets/img/services/services_4.jpeg" className="card-img-bottom img_round2" alt="management" style={{width: "100%", height:"14vw", objectFit:"cover"}}/>
                                            
                                                </div>
                                                
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>


                                {/* "Project Management" section */}
                                <div>
                                    <header className="section-header-little-margin">
                                        <p> Project Management </p>
                                        <h2> Find Experts to Help Run Successful Projects </h2>
                                    </header>

                                    <div className="container" data-aos="fade-up">
                                        <p style={{textAlign:'center'}}>All projects <b>are unique</b> <br/>and yours will come with its <b>own unique challenges</b></p>
                                       
                                        <img src="assets/img/services/consulting_pm.jpg" className="mx-auto d-block w-100 h-100" alt=""/>
                                        <br />
                                        <div className="card-body" style={{width:'300px', top:'50px', left:'50px', position:'absolute', backgroundColor:'#E2EAF7'}}>
                                            <p className="card-text">We provide highly experienced program managers and project managers with the <b>leadership capabilities</b> to successfully guide, manage, and support high-visibility initiatives.</p>
                                        </div>

                                        <div className="tab-content align-middle">
                                            <div className="line d-flex mb-2">
                                                <i className="bi bi-check2"></i>
                                                <p>We can lead the planning, organization and control of resources linked to IT projects, guaranteeing profitability, quality and meeting deadlines.</p>
                                            </div>
                                            <div className="line d-flex mb-2">
                                                <i className="bi bi-check2"></i>
                                                <p>Whether you are looking for support with an initial project development or a total project management solution, you can rely on us ! As your project partner, you get access to services spanning the entire project lifecycle, from initiation to implementation and rollout, reinforced by decades-long project management know-how.</p>
                                            </div>
                                            <div className="line d-flex mb-2">
                                                <i className="bi bi-check2"></i>
                                                <p>Working closely with yours resources as part of a fully integrated team, our experts will help out in guiding your choices.</p>
                                            </div>
                                        </div>   
                                    </div>
                                </div>

                                {/* "Solution Architecture" section */}
                                <div>
                                    <header className="section-header-little-margin">
                                        <p> Solution Architecture </p>
                                        <h2> What is a solutions architect? A vital role for IT-business alignment </h2>
                                    </header>

                                    <div style={{textAlign:'center'}}>
                                        <p>
                                            Nowadays, for a business to run successfully, technology is vital. Carrying out processes, exchanging information, managing data and resources, all make the implementation of IT tools necessary. For a digital business, the need is even greater. In order to create an outstanding and desirable digital product, you need to choose the right technology. 
                                        </p>
                                        <p>
                                            How? <b>Use the expertise of a solution architect.</b>
                                        </p>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col-lg-6 d-flex justify-content-center">
                                            <img src="assets/img/services/consulting_sa.png" className="img-fluid img_round2" alt=""/>
                                        </div>

                                        <div className="col-lg-6 mt-5 mt-lg-0 d-flex align-items-center">
                                            <div className="tab-content align-middle">
                                                <div className="line d-flex mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p>We can provide detailed architectural support, translating enterprise and technical strategies into a deliverable and effective solution design. This includes support to create user stories/epics, strategic assessment, solution assessment, design, documentation, testing and quality assurance.</p>
                                                </div>
                                                <div className="line d-flex mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p>Solution architects <b>work hand in</b> hand with deep technical experts, users and strategic leadership.</p>
                                                </div>
                                                <div className="line d-flex mb-2">
                                                    <i className="bi bi-check2"></i>
                                                    <p>We have years of experience as solution architects to implement ERPs in large organizations as well as guiding promising startups in their product development.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* "IT Solution Consulting" section */}
                                <div>
                                    <header className="section-header-little-margin">
                                        <p> IT Solution Consulting </p>
                                        <h2> Solution Design Promote Project Success </h2>
                                    </header>

                                    <div className="container" data-aos="fade-up" style={{textAlign:'center'}}>
                                        <p>Software solutions can be <b>game-changing</b> to your organization.</p>
                                        <p>Whether you provide a service or product offering or want to enhance the in-house efficiency of operations and workflows, a well-designed software solution can offer a world of difference.</p>
                                        <p>Solution design is the first step towards the eventual physical deployment of any solution. It’s a blueprint for what needs to be built out.</p>
                                        <p><b>Our teams possess strong technical and functional skill sets and knowledge</b>, leveraging this to design an optimum and practical solution for your business needs.</p>
                                    </div>

                                    <div className="tab-content" style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
                                        <div className="line d-flex mb-2" style={{ width: "220px" }}>
                                            <i className="bi bi-check2"></i>
                                            <p>Planview AdaptiveWork</p>
                                        </div>
                                        <div className="line d-flex mb-2" style={{ width: "220px" }}>
                                            <i className="bi bi-check2"></i>
                                            <p>Planview Changepoint</p>
                                        </div>
                                        <div className="line d-flex mb-2" style={{ width: "220px" }}>
                                            <i className="bi bi-check2"></i>
                                            <p>Planview AgilePlace</p>
                                        </div>
                                        <div className="line d-flex mb-2" style={{ width: "220px" }}>
                                            <i className="bi bi-check2"></i>
                                            <p>Planview Daptiv</p>
                                        </div>
                                        <div className="line d-flex mb-2" style={{ width: "220px" }}>
                                            <i className="bi bi-check2"></i>
                                            <p>Monday.com</p>
                                        </div>
                                        <div className="line d-flex mb-2" style={{ width: "220px" }}>
                                            <i className="bi bi-check2"></i>
                                            <p>ServiceNow</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    
                    <BackToTopButton />
                </main>
                
            </BasicLayout>
        </ScrollToTop>
    );
}
