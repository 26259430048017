import BasicLayout from "../../layouts/BasicLayout"
import BreadCrumbs from "../../components/common/BreadCrumbs"
import ScrollToTop from "../../components/common/ScrollToTop";
import BackToTopButton from "../../components/common/BackToTopButton";
import { useTranslation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';
import { useEffect } from 'react';

export default ({children}) => {
    const { t, i18n } = useTranslation();

    /*
    *   This useEffect() is used to solve the problem with the loading of the
    *   animations, navbar navigation, etc on the pages that aren't the main page
    */
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/assets/js/main.js";
        document.body.appendChild(script);
    }, []);

    const yellow = '#E8AB13'
    const gray = '#3C3535' 
    const red = '#A5201F' 
    const white = '#FFF'

    return (
        <ScrollToTop>
            <BasicLayout>
                <main>
                    <BreadCrumbs path='Support' title='Support Services' />

                    {/* "We treat our customers like people, not tickets" section */}
                    <div>
                        <header className="section-header-little-margin" data-aos="fade-up">
                            <p> Our Support Center </p>
                            <h2> We treat our customers like people, not tickets </h2>
                        </header>

                        <section className="about">
                            <div className="container" >
                                <div className="row gx-0">
                                    <div className="col-lg-6 content d-flex align-items-center" data-aos="fade-up" data-aos-delay="200">
                                        <p>Outsourced help desk serves to promptly manage administration, functional and technical support of your  IT infrastructure.<br/><br/>
                                        Providing Support Center services since 2015, we have defined and built a support center allowing customers to have a single point of communication to assure that their needs and issues are taken care in a proper, professional and quick way for both IT and non-IT companies.</p>
                                    </div>

                                    <div className="col-lg-6 d-flex align-items-center" data-aos="fade-down" data-aos-delay="200">
                                        <img src="assets/img/services/support_page.webp" className="img-fluid" alt=""/>
                                    </div>
                                </div>

                            </div>
                        </section>
                    </div>


                    

                    {/* "Better than a traditional help desk" section */}
                    <div>
                        <section className="solutions">
                            <div className="container" data-aos="fade-up">
                                <header className="section-header-little-margin">
                                    <p> Support Center Services </p>
                                    <h2> Better than a traditional help desk </h2>
                                </header>
                                <div style={{textAlign:'center'}}>
                                    <p>
                                        We have <b>different levels of services</b> provided via the support portal such as, system administration, bug fixes, evolutive maintenance. 
                                    </p>
                                    <p>
                                        Our most frequent type of support contracts are:
                                    </p>
                                </div>

                                <div className="row">
                                    <div className="col-lg-4 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="100">
                                        <div className="box">
                                            <h3 style={{color: '#A52020'}}> BANK OF HOURS </h3>
                                            <img src="assets/img/services/bank_hours.jpeg" className="img-fluid" alt="" />
                                            <br/><br/>
                                            <p> Hours to be consumed of support activities to evolutive services </p>
                                            <br/><br/>   
                                            <Link 
                                                to='/home#contacts'
                                                className="read_more_button position-absolute bottom-0 start-50 translate-middle" 
                                                style={{backgroundColor:'#A52020' }}
                                            >
                                                <span> Contact Us </span>
                                                <i className="bi bi-arrow-right"></i>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                                        <div className="box">
                                            <h3 style={{color: '#E8AC14'}}> DEDICATED RESOURCE </h3>
                                            <img src="assets/img/services/dedicated_resource.jpeg" className="img-fluid" alt="" />
                                            <br/><br/>
                                            <p> Dedicated resource tailored to customer system that will perform any support activity</p>
                                            <br/><br/>    
                                            <Link 
                                                to='/home#contacts'
                                                className="read_more_button position-absolute bottom-0 start-50 translate-middle" 
                                                style={{backgroundColor:'#E8AC14' }}
                                            >
                                                <span> Contact Us </span>
                                                <i className="bi bi-arrow-right"></i>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="300">
                                        <div className="box">
                                            <h3 style={{color: '#3C3535'}}> POOL OF SUPPORT ENGINEERS </h3>
                                            <img src="assets/img/services/support_engineers_2.jpeg" className="img-fluid" alt="" />
                                            <br/><br/>
                                            <p> Pool of support engineers will attend to your needs </p>
                                            <br/><br/>    
                                            <Link 
                                                to='/home#contacts'
                                                className="read_more_button position-absolute bottom-0 start-50 translate-middle" 
                                                style={{backgroundColor:'#3C3535' }}
                                            >
                                                <span> Contact Us </span>
                                                <i className="bi bi-arrow-right"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <p style={{marginTop:"30px", textAlign:'center'}}>Your organization can engage with us in different ways, contact us for more information and get a <b>solution that fits your organization</b>.</p>

                            </div>
                        </section>
                    </div>

                    {/* "Wy choose us" section */}
                    <div>
                        <header className="section-header-little-margin" data-aos="fade-up">
                            <h2> Why choose us </h2>
                        </header>

                        <section className="about">
                            <div className="container" data-aos="fade-up">
                                
                                <div className="tab-content align-middle">
                                    <div className="row">
                                        <div className="col-lg-6 col-sm-12 line d-flex align-items-center">
                                            <i className="bi bi-check2" />
                                            <p>Establishment of a direct and instant communication channel using Slack, Teams, Hang out.</p>
                                        </div>
                                        <div className="col-lg-2  d-flex justify-content-center">
                                            <img src="assets/img/services/teams.png" className="card-img" alt="teams" style={{minWidth: '75px', maxWidth:'125px'}}/>
                                        </div>
                                        <div className="col-lg-2 d-flex justify-content-center">
                                            <img src="assets/img/services/hangouts.png" className="card-img" alt="hangouts" style={{minWidth: '75px', maxWidth:'125px'}}/>
                                        </div>
                                        <div className="col-lg-2  d-flex justify-content-center">
                                            <img src="assets/img/services/slack.png" className="card-img" alt="slack" style={{minWidth: '75px', maxWidth:'125px'}}/>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-6 col-sm-12 line d-flex align-items-center">
                                            <i className="bi bi-check2" />
                                            <p>Service Level Agreements (SLA) measurement and full request visibility using best of the market tools such as Jira available for customers.</p>
                                        </div>
                                        <div className="col-lg-6 d-flex justify-content-center">
                                            <img src="assets/img/services/jira.png" className="card-img" alt="jira" style={{ maxWidth:'200px' }}/>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-6 col-sm-12 line d-flex align-items-center">
                                            <i className="bi bi-check2" />
                                            <p>ITIL compliant processes and specialized dedicated resources.</p>
                                        </div>
                                        <div className="col-lg-6 d-flex justify-content-center">
                                            <img src="assets/img/services/process.png" className="card-img" alt="process" style={{maxWidth:'175px'}}/>
                                        </div>
                                    </div>

                                    <div className="line d-flex align-items-center">
                                        <i className="bi bi-check2" />
                                        <p>More than 10 years experience in IT.</p>
                                    </div>
                                </div>
            
                            </div>
                        </section>
                    </div>
                    {/* "Service desk best practices you should know" section */}
                    <div>
                        <section className="features" >
                            <div className="container" data-aos="fade-up">
                                <header className="section-header-little-margin">
                                    <p> Processes and Best Practices </p>
                                    <h2> Service Desk Best Practices You Should Know </h2>
                                </header>
                                <p >
                                    When it comes to providing IT support, it’s ideal to minimize the time and money you spend on processes while ensuring timely responses to all requests. However, if you’re not following <b>ITIL</b> best practices, it’s easy for tickets to pile up and slow down your team’s ability to process them.
                                </p>
                                
                                <div className="row">
                                    <div className="col-lg-6 d-flex align-self-center">
                                        <img src="assets/img/services/support.jpeg" className="img-fluid img_round2" alt=""/>
                                    </div>

                                    <div className="col-lg-6 mt-5 mt-lg-0 d-flex">
                                        <div className="col align-self-center gy-4">                                            
                                            <p>Considering the Information Technology Infrastructure Library (ITIL) framework, we built and configured several processes in our support center following the <b>best practices and guidelines</b>. </p>
                                            <p>Thus, the center supports the following management processes:</p>
                                            
                                            <div className="tab-content align-middle justify-content-center">
                                                <ul className="red">
                                                    <li> Incident </li>
                                                    <li> Problem </li>
                                                    <li> Change </li>
                                                    <li> Release </li>
                                                    <li> Service </li>
                                                </ul>
                                                
                                                {/* <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" />
                                                    <p>Incident</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" />
                                                    <p>Problem</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" />
                                                    <p>Change</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" />
                                                    <p>Release</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" />
                                                    <p>Service</p>
                                                </div> */}
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </section>
                    </div>

                    


                    
                    
                    <BackToTopButton />
                    
                </main>
            </BasicLayout>
        </ScrollToTop>
    );
}
