import { useTranslation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';

export default (props) => {
    const { t, i18n } = useTranslation();
    
    return(
        <section id="solutions" className="solutions">
            <div className="container" data-aos="fade-up">
                <header className="section-header">
                    <p>{t('solutions_p')}</p>
                    <h2>{t('solutions_h2')}</h2>
                </header>

                {/* Solutions' first row */}
                <div className="row" style={{display:'flex'}}>
                    {/* Mangify - solutions' first row and first col */}
                    <div className="col-lg-4 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="300" style={{padding:'1.5em'}}>
                        <div className="box">
                            <h3 style={{color: '#7A74FF'}}>{t('solutions_magnify_h3')}</h3>
                            <img src="assets/img/magnify.png" className="img-fluid" alt="" />
                            <ul>
                                <li>{t('solutions_magnify_li')}</li>
                            </ul>
                            <div style={{paddingBottom:'25px'}}>
                                <p> "<b>Magnify+ </b>{t('solutions_magnify_p')} " </p>
                            </div>
                            <Link 
                                to='/magnify' 
                                className="read_more_button position-absolute bottom-0 start-50 translate-middle" 
                                style={{backgroundColor:'#7A74FF' }}
                            >
                                <span> {t('read_more')} </span>
                                <i className="bi bi-arrow-right"></i>
                            </Link>
                        </div>
                    </div>

                    {/* Changepoint - solutions' first row and second col */}
                    <div className="col-lg-4 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="100" style={{ padding:'1.5em'}}>
                        <div className="box">
                            <h3 style={{color:'#A52020'}}> {t('solutions_changepoint_h3')} </h3>
                            <img src="assets/img/changepoint-logo.png" className="img-fluid" alt="changepoint" />
                            <ul>
                                <li> {t('solutions_changepoint_li1')} </li>
                                <li> {t('solutions_changepoint_li2')}</li>
                                <li> {t('solutions_changepoint_li3')}</li>
                                <li> {t('solutions_changepoint_li4')}</li>
                            </ul>
                            <div style={{paddingBottom:'25px'}}>
                                <p>"<b>Changepoint </b> {t('solutions_changepoint_p1')} </p>
                                <p> {t('solutions_changepoint_p2')}</p>
                                <p> {t('solutions_changepoint_p3')}" </p>
                            </div>
                            <Link 
                                to='/changepoint'
                                className="read_more_button position-absolute bottom-0 start-50 translate-middle" 
                                style={{backgroundColor:'#A52020'}}
                            >
                                <span> {t('read_more')} </span>
                                <i className="bi bi-arrow-right"></i>
                            </Link>
                        </div>
                    </div>

                    {/* Clarizen - solutions' first row and third col */}
                    <div className="col-lg-4 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="400" style={{padding:'1.5em'}}>
                        <div className="box">
                            <h3 style={{color: '#E8AC14'}}>{t('solutions_clarizen_h3')}</h3>
                            <img src="assets/img/planview_adaptivework_logo.png" className="img-fluid" alt="" />
                            <ul>
                                <li>{t('solutions_clarizen_li1')}</li>
                                <li>{t('solutions_clarizen_li2')}</li>
                                <li>{t('solutions_clarizen_li3')}</li>
                                <li>{t('solutions_clarizen_li4')}</li>
                                <li>{t('solutions_clarizen_li5')}</li>
                            </ul>
                            <div style={{paddingBottom:'25px'}}>
                                <p> "<b>AdaptiveWork </b>{t('solutions_clarizen_p')}" </p>
                            </div>
                            <Link 
                                to='/clarizen' 
                                className="read_more_button position-absolute bottom-0 start-50 translate-middle" 
                                style={{backgroundColor:'#E8AC14' }}
                            >
                                <span> {t('read_more')} </span>
                                <i className="bi bi-arrow-right"></i>
                            </Link>
                        </div>
                    </div>
                </div>

                {/* Solutions' second row */}
                <div className="row" style={{display:'flex', marginBottom:'30px'}}>
                    {/* Agile Place - solutions' second row and first col*/}
                    <div className="col-lg-4 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="500" style={{padding:'1.5em'}}>
                        <div className="box">
                            <h3 style={{color: '#4372C4'}}> {t('ENTERPRISE KANBAN')} </h3>
                            <img src="assets/img/planview-agileplace-dark.svg" className="img-fluid" alt="agil place" />
                            <ul>
                                <li>{t('Go Agile')}</li>
                                <li>{t('See the big picture')}</li>
                                <li>{t('Drive productivity across teams')}</li>
                                <li>{t('Integrate work delivery')}</li>
                                <li>{t('Promote continuous improvement')}</li>
                            </ul>
                            <div style={{paddingBottom:'25px'}}>
                                <p>"<b>Agile Place </b> {t('enables organizations and teams to plan, track, collaborate by using kanban boards and visualize metrics to improve delivery and stay aligned to strategic objectives without sacrificing delivery speed or quality.')}"</p>
                            </div>
                            <Link 
                                to='/agilplace' 
                                className="read_more_button position-absolute bottom-0 start-50 translate-middle" 
                                style={{backgroundColor:'#4372C4' }}
                            >
                                <span> {t('read_more')} </span>
                                <i className="bi bi-arrow-right"></i>
                            </Link>
                        </div>
                    </div>
                    
                    {/* Barometer - solutions' second row and second col */}
                    <div className="col-lg-4 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="500" style={{padding:'1.5em'}}>
                        <div className="box">
                            <h3 style={{color: '#3C3535'}}> {t('solutions_barometer_h3')} </h3>
                            <img src="assets/img/barometer.png" className="img-fluid" alt="barometer" />
                            <ul>
                                <li> {t('solutions_barometer_li1')} </li>
                                <li> {t('solutions_barometer_li2')} </li>
                                <li> {t('solutions_barometer_li3')} </li>
                            </ul>
                            <div style={{paddingBottom:'25px'}}>
                                <p>"<b>barometerIT </b> {t('solutions_barometer_p1')} </p>
                                <p> {t('solutions_barometer_p2')} </p>
                                <p> {t('solutions_barometer_p3')}"</p>
                            </div>
                            <Link 
                                to='/barometer' 
                                className="read_more_button position-absolute bottom-0 start-50 translate-middle" 
                                style={{backgroundColor:'#3C3535' }}
                            >
                                <span> {t('read_more')} </span>
                                <i className="bi bi-arrow-right"></i>
                            </Link>
                        </div>
                    </div>

                    {/* monday.com - solutions' second row and third col*/}
                    <div className="col-lg-4 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="500" style={{padding:'1.5em'}}>
                        <div className="box">
                            <h3 style={{color: '#FA1B51'}}> {t('ENTERPRISE WORKFLOWS')} </h3>
                            <img src="assets/img/solutions/monday/monday_logo.png" className="img-fluid" alt="monday.com" />
                            <ul>
                                <li>{t('Shape workflows, your way')}</li>
                                <li>{t('Boost your team\'s productivity')}</li>
                                <li>{t('Centralize all your work and processes')}</li>
                                <li>{t('Collaborate effectively organization-wide')}</li>
                            </ul>
                            <div style={{paddingBottom:'25px'}}>
                                <p>"<b>Monday.com </b> {t('is a one source of truth for all your projects\' data.')}"</p>
                                <p>{t('It is a centralised platform that does it all - communication, planning, resource allocation, reporting, and more.')}</p>
                            </div>
                            <Link 
                                to='/monday' 
                                className="read_more_button position-absolute bottom-0 start-50 translate-middle" 
                                style={{backgroundColor:'#FA1B51' }}
                            >
                                <span> {t('read_more')} </span>
                                <i className="bi bi-arrow-right"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}