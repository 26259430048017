import BasicLayout from "../../layouts/BasicLayout"
import BreadCrumbs from "../../components/common/BreadCrumbs"
import ScrollToTop from "../../components/common/ScrollToTop";
import BackToTopButton from "../../components/common/BackToTopButton";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default ({children}) => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/assets/js/main.js";
        document.body.appendChild(script);
    }, []);

    const themeColor = "#4372C4"

    return (
        <ScrollToTop>
            <BasicLayout>
                <main>
                    <BreadCrumbs path='Solutions' title='Planview AgilePlace' />
                    <div>
                        <header className="section-header-little-margin" data-aos="fade-up">
                            <p> Planview AgilePlace </p>
                            <h2 style={{color:themeColor}}> ADOPT A LEAN AND AGILE APPROACH </h2>
                        </header>
                        
                        {/* "Connect and engage your workforce across the enterprise" section*/}
                        <section className="about">
                            <div className="container" >

                                <div className="row gx-0">
                                    <div className="col-lg-6 content d-flex align-items-center" data-aos="fade-right" data-aos-delay="200" style={{backgroundColor:'#3C3535'}}>
                                        <p><b>Planview AgilePlace</b> is designed to help teams work more efficiently and collaboratively in an Agile development environment. <br/> <br/>
                                        It provides a range of features to support Agile methodologies, such as Kanban boards, sprint planning, and retrospectives.</p>
                                    </div>

                                    <div className="col-lg-6 d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="300" style={{backgroundColor:themeColor, padding:'75px'}}>
                                        <img className='img-fluid' src="assets/img/solutions/agilplace/agileplace-logo.png" alt="planview agile place" />
                                    </div>
                                </div>
                                <br />
                                <p style={{textAlign: 'center'}} data-aos="fade-up">
                                    For more information, please visit the official page: <a href="https://www.planview.com/products-solutions/products/agileplace/" target="_blank" style={{color:themeColor}}> Here!</a>
                                </p>
                            </div>
                        </section>
                    </div>

                    <div>
                        <header className="section-header-little-margin" data-aos="fade-up">
                            <p> Key Features </p>
                            <h2 style={{color: themeColor}}>How Kanban can be good for you</h2>
                        </header>
    
                        <section className="collumns blue" >
                            <div className="container" data-aos="fade-up">
                                <div className="row main ">
                                    <div className="col-lg-12 d-flex justify-content-center" data-aos="zoom-out" data-aos-delay="200">
                                        <img src="assets/img/solutions/agilplace/agileplace_graph.png" className="img-fluid img_round2" alt=""/>
                                    </div>
                                </div>

                                <div className="row main">
                                    <div className="col-lg-6 d-flex align-self-center" data-aos="zoom-out" data-aos-delay="100">
                                        <img src="assets/img/solutions/agilplace/depedency-risk-management.png" className="img-fluid img_round2" alt=""/>
                                    </div>

                                    <div className="col-lg-6 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4"> 
                                            <p>Planview AgilePlace also includes project management capabilities, such as resource allocation, budget tracking, and reporting, to help teams stay on track and deliver high-quality products on time and within budget. <br /> <br /> 
                                            Overall, Planview AgilePlace is designed to streamline the Agile development process and make it easier for teams to deliver value to their customers.</p> 
                                            <div className="tab-content align-middle">
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: themeColor}} />
                                                    <p>Cross-Team Collaboration, Planning and Delivery.</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: themeColor}} />
                                                    <p>Performance Metrics, Analytics and Data.</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: themeColor}} />
                                                    <p>Work and Value Visualization.</p>
                                                </div>
                                                <div className="line d-flex align-items-center mb-2">
                                                    <i className="bi bi-check2" style={{color: themeColor}} />
                                                    <p>Deliver Predictability, Risks and Insights.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row main">
                                    <div className="col-lg-4 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4">   
                                            <h1>Virtual collaboration</h1> 
                                            <p>Online space for increased collaboration.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-8 d-flex align-self-center" data-aos="zoom-out" data-aos-delay="200">
                                        <img src="assets/img/solutions/agilplace/instant-coffee-leankit.png" className="img-fluid img_round2" alt=""/>
                                    </div>
                                </div>

                                <div className="row main">
                                    <div className="col-lg-8 d-flex align-self-center" data-aos="zoom-out" data-aos-delay="200">
                                        <img src="assets/img/solutions/agilplace/kanban-img.png" className="img-fluid img_round2" alt=""/>
                                    </div>

                                    <div className="col-lg-4 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4">   
                                            <h1>Full Visibility </h1> 
                                            <p>Offer a single, comprehensive view of task priority and status.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row main">
                                    <div className="col-lg-4 mt-5 mt-lg-0 d-flex" data-aos="fade-up" data-aos-delay="200">
                                        <div className="col align-self-center gy-4">   
                                            <h1>Interactive roadmap</h1> 
                                            <p>Create a visual timeline to achieve business goals and deliverables.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-8 d-flex align-self-center" data-aos="zoom-out" data-aos-delay="200">
                                        <img src="assets/img/solutions/agilplace/eap_roadmap.jpeg" className="img-fluid img_round2" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                    <BackToTopButton />

                </main>
            </BasicLayout>
        </ScrollToTop>
    );
}