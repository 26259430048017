import BasicLayout from "../layouts/BasicLayout"
import BreadCrumbs from "../components/common/BreadCrumbs"
import { useTranslation } from 'react-i18next';
import ScrollToTop from "../components/common/ScrollToTop"
import BackToTopButton from "../components/common/BackToTopButton";
import { useEffect } from 'react';

export default (props) => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/assets/js/main.js";
        document.body.appendChild(script);
    }, []);

    return (
        <ScrollToTop>
            <BasicLayout>
                <main>
                    <BreadCrumbs path='Privacy' title='Privacy Policy' />
                    <section id="privacy">
                        <div className="container" data-aos="fade-up">
                            <header className="section-header">
                                <p> Privacy Policy </p>
                                <h2> Here you can find our's privacy policies </h2>
                            </header>
                            <div>
                                <br />
                                <p> {t('privacy_p1')}: </p>
                                <ul>
                                    <li> {t('privacy_li1')} </li>
                                    <li> {t('privacy_li2')} </li>
                                    <li> {t('privacy_li3')} </li>
                                    <li> {t('privacy_li4')} </li>
                                    <li> {t('privacy_li5')} </li>
                                    <li> {t('privacy_li6')} </li>
                                    <li> {t('privacy_li7')} </li>
                                </ul>
                                <p> {t('privacy_p2')} </p>
                                <br />
                            </div>
                        </div>
                    </section>

                    <BackToTopButton />
                    
                </main>
            </BasicLayout>
        </ScrollToTop>
    );
}
