import { useTranslation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';

export default (props) => {
    const { t, i18n } = useTranslation();
    
    return(
        <section id="services" className="services">
            <div className="container" data-aos="fade-up">
                
                <header className="section-header">
                    <p>{t('services_p')}</p>
                    <h2>{t('services_h2')}</h2>
                </header>
                
                <div className="row gy-4">
                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200" style={{marginBottom:'30px'}}>
                        <img src="assets/img/consulting.jpeg" className="img-fluid img_round2" alt="consulting"/>
                        <div className="service-box red h-50" >
                            <h3> {t('services_consulting_h3')} </h3>
                            <p> {t('services_consulting_p')} </p>
                            <Link to='/consulting' className="read-more"><span> {t('read_more')} </span><i className="bi bi-arrow-right"></i></Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300" style={{marginBottom:'30px'}}>
                        <img src="assets/img/support.jpg" className="img-fluid img_round2" alt="support"/>
                        <div className="service-box yellow h-50">
                            
                            <h3> {t('services_support_h3')} </h3>
                            <p> {t('services_support_p')} </p>
                            <Link to='/support' className="read-more"><span> {t('read_more')} </span><i className="bi bi-arrow-right"></i></Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400" style={{marginBottom:'30px'}}>
                        <img src="assets/img/dev.jpeg" className="img-fluid img_round2" alt="development"/>
                        <div className="service-box grey h-50">
                            
                            <h3> {t('services_development_h3')} </h3>
                            <p> {t('services_development_p')} </p>
                            <Link to='/development' className="read-more"><span> {t('read_more')} </span><i className="bi bi-arrow-right"></i></Link>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
}