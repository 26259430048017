import BasicLayout from '../layouts/BasicLayout'
import Contact from "../components/Contact"
import BackToTopButton from "../components/common/BackToTopButton"
import About from '../components/home/About'
import HeroSection from "../components/home/Hero"
import Services from "../components/home/Services"
import Solutions from "../components/home/Solutions"
import Customers from "../components/home/Customers"
import SuccessCases from '../components/home/SuccessCases'
import CookieConsent from "react-cookie-consent";
import { useTranslation } from 'react-i18next';

export default () => {
    const { t } = useTranslation();
    
    return (
        <BasicLayout>
            <CookieConsent
                location="bottom" 
                buttonText={t('privacy_cookie_button')}
                overlay
            > 
                {t('privacy_cookie_text')}
            </CookieConsent>

            <HeroSection />
            <main>
                <About />
                <Services />
                <Solutions />
                <Customers />
                <SuccessCases />
                <Contact pTitle={t('home_contact_p')} h2Title={t('home_contact_h2')}/>
                <BackToTopButton />
            </main>
        </BasicLayout>
    );
}
