
import { useTranslation } from 'react-i18next';
import React from 'react';
import { Chrono } from "react-chrono";
import ModalWindow from './ModalWindow';

/**
 *  Component created using react-chrono package.
 *  Docs: https://www.npmjs.com/package/react-chrono
 */
export default (props) => {
    const { t, i18n } = useTranslation();

    /*
    *   This items array is where we put the cards that appear in the timeline.
    *   @Title - the year to be shown in the timeline
    *   @cardTile - the title to be shown in the card
    *   @cardDetailedText - the text to be shown under the title
    *   @cardTile and @cardDetailedText can be defined:
    *       . here with strings
    *       . inside a div with the "<Chrono>" as parent element (option used)
    * 
    *   Learn more here: https://www.npmjs.com/package/react-chrono#rendering-custom-content
    */
    const items = [
        /*
        {
            title: "Now",
            cardTitle: "",
            cardDetailedText: ``,
        },
        */
        {
            title: "Now",
            cardTitle: "",
            cardDetailedText: ``,
        },
        {
            title: "2020",
            cardTitle: "",
            cardSubtitle: ``
        },
        {
            title: "2018",
            cardTitle: "",
            cardSubtitle: ``
        },
        {
            title: "2015",
            cardTitle: "",
            cardSubtitle: ``
        },
        {
            title: "2013 - 2014",
            cardTitle: "",
            cardSubtitle: ``
        },
        {
            title: "2012",
            cardTitle: "",
            cardSubtitle: ``
        },
    ];
    
    const red = "#A52020"
    const white = "#FFF"

    /*
    *   PS: the CSS used in this component is in "style.css" file under "Timeline" section
    */
    return (
        <section id="timeline" className="timeline">
            <div className="container">
                <header className="section-header-little-margin">
                    <p> Our History </p>
                    <h2> What brought us here </h2>
                </header>
                
                <div style={{ width: "auto", height: "auto" }}>
                    {/*
                    *   The <Chrono> element supports several props like the ones used here:
                    *       . @items - collection of Timeline Item Model defined in the array "items"
                    *       . @mode - orientation of the timeline (horizontal or vertical)
                    *       . @borderLessCards - to remove the border of each card
                    *       . @theme - used to change the colors of the timeline
                    *           . @primary - color of the timeline
                    *           . @secondary - color of the box around the year when selected
                    *           . @cardBgColor - card's background color
                    *           . @titleColor - year's color when selected
                    * 
                    *   Learn more here: https://www.npmjs.com/package/react-chrono#props
                    */}
                    <Chrono 
                        items={items} 
                        mode="VERTICAL_ALTERNATING"
                        borderLessCards="true"
                        hideControls="true"
                        theme={{
                            primary: red,
                            secondary: red,
                            cardBgColor: white,
                            titleColor: white
                        }}
                    >
                        {/*
                        *   Used the ids "left" and "right" on <div> components just to align the cards 
                        *   to the left or to the right, depending on wich side of the timeline they are.
                        */}
                        {/*
                        <div id="left">
                            <h4>Changepoint Partnership</h4>
                            <p>Today we’re proud to work with most advanced organizations on the globe. <br />Since the first project, we have help customers to improve theirs processes and achieve better results.</p>
                            <p>We continue to be an editor partner that is valuable and trustable.</p>
                            <p>Every day, our perseverance pays off in small ways.</p>
                            <p>Number of projects are increasing and we are proud that our family is also growing.</p>
                            <p>We invite you to become part of our story.</p>
                        </div>
                        */}

                        <div id="right">
                            <h4>NEW IDENTITY</h4>
                            <p></p>
                            <p>As we are always looking for challenges, our previous graphical identity was not matching our energy and dynamism.</p>
                            <p>Check the creative process and the story behind it !</p>
                            <ModalWindow />
                        </div>

                        <div id="left">
                            <h4>DEV CENTER SERVICE</h4>
                            <p>We open  a new service: development in nearshore.</p>
                            <p>First application is developped - Magnify+</p>
                        </div>
                        
                        <div id="right">
                            <h4>FRANCE ENTITY OPENING</h4>
                            <p>We open a new entity in France to get into the French market and always to be closer of our customers.</p>
                            <p>First project in France.</p>
                        </div>

                        <div id="left">
                            <h4>REFERENCIAL RESSELER</h4>
                            <p>We continue to work with customers all over the world.</p>
                            <p>
                                We are now Changepoint referencial reseller for following solutions:<br />
                                Changepoint / Daptiv / barometerIT / Intelledox / COGNOS
                            </p>
                        </div>

                        <div id="right">
                            <h4>PARTNERSHIP</h4>
                            <p>We are certified GOLD partner with Changepoint software editor (Compuware in the past). </p>
                            <p> A wordwide company positioned in the "Leaders" Quadrant of Gartner Magic Quadrant for Integrated IT Porfolio Analysis Applications.</p>
                        </div>

                        <div id="left">
                            <h4>FOUNDED</h4>
                            <p>HB-IT Consulting is founded based in Lisbon.</p>
                            <p>Since its origins been marked by international projects.</p>
                        </div>
                        
                    </Chrono>
                </div>
            </div>
        </section>
        
    );
}