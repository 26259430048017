import { useTranslation } from 'react-i18next';

export default () => {
  const { t, i18n } = useTranslation();

    return(
      <section id="hero" className="hero d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 d-flex flex-column justify-content-center">
              <video className="background-video" autoPlay loop muted >
                <source src="/assets/test4.mp4" type="video/mp4"></source>
              </video>
              <h1 data-aos="fade-up">{t('hero_h1')}</h1>
              <h2 data-aos="fade-up" data-aos-delay="400">{t('hero_h2')}</h2>
              <div data-aos="fade-up" data-aos-delay="600">
                <div className="text-center">
                  <a href="#about" className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                    <span>{t('hero_get_started')}</span>
                    <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}