export default (props) => {
  return(
    <div>
      <section className="breadcrumbs">
        <div className="container">
          <ol>
            <li><a href="/home">Home</a></li>
            <li>{props.path}</li>
          </ol>
          <h2>{props.title}</h2>
        </div>
      </section>
    </div>
  );
}