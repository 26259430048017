import { useTranslation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';

export default (props) => {
    const { t, i18n } = useTranslation();
    
    return(
        <div>
            <footer id="footer" className="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row gy-4">
                            
                            <div className="col-lg-6 col-md-12 footer-info">
                                <div className='row'>
                                    <div className='col-2'>
                                        <img src='assets/img/logo_grey.png' alt="" style={{width:"100%"}}/>
                                    </div>
                                    <div className='col-10'>
                                        <p style={{color: '#FFF', marginLeft: '10px'}}> {t('footer_text1')} </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-2 col-6 footer-links">
                                <h4>{t('footer_h4_links')}</h4>
                                <ul>
                                    <li><i className="bi bi-chevron-right"></i> <Link to='/home#hero'><span>{t('navbar_home')}</span></Link> </li>
                                    <li><i className="bi bi-chevron-right"></i> <Link to='/about'><span>{t('navbar_about')}</span></Link> </li>
                                    <li><i className="bi bi-chevron-right"></i> <Link to='/privacy'><span>{t('footer_privacy')}</span></Link> </li>
                                    <li><i className="bi bi-chevron-right"></i> <Link to='/terms'><span>{t('footer_terms')}</span></Link> </li>
                                    <li><i className="bi bi-chevron-right"></i> <Link to='/partners'><span>{t('footer_partners')}</span></Link> </li>
                                </ul>
                            </div>

                            <div className="col-lg-2 col-6 footer-links">
                                <h4>{t('footer_h4_services')}</h4>
                                <ul>
                                    <li><i className="bi bi-chevron-right"></i><Link to='/consulting'>{t('navbar_services_consulting')}</Link></li>
                                    <li><i className="bi bi-chevron-right"></i><Link to='/support'>{t('navbar_services_support')}</Link></li>
                                    <li><i className="bi bi-chevron-right"></i><Link to='/development'>{t('navbar_services_development')}</Link></li>
                                </ul>
                            </div>

                            <div className="col-lg-2 col-md-12 footer-contact text-center text-md-start">
                                <h4> {t('footer_h4_contact')} </h4>
                                <p>info@hb-itconsulting.pt<br/></p>
                                <div className="social-links mt-3">
                                    <a href="https://www.facebook.com/HBITConsulting" className="facebook" target="_blank">
                                        <i className="bi bi-facebook"></i>
                                    </a>
                                    <a href="https://www.linkedin.com/company/hb-it-consulting?challengeId=AQFs5QFYDk5QBgAAAX__tsZgAynEnVQlKc_vSlvaY0nZGE3A4YjxZ1AyOtdOiF8HAWLoflQRkjplnU6r-N-fBC7upCPrVTUfPg&submissionId=f0b2f49c-a25b-e316-47b6-5c3f0f842c30" className="linkedin" target="_blank">
                                        <i className="bi bi-linkedin bx bxl-linkedin"></i>
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="copyright">
                    {t('footer_copyright')} &copy; 2012-{new Date().getUTCFullYear()} <strong><span>HB IT Consulting</span></strong>. {t('footer_rights')}.
                    </div>
                    
                </div>
            </footer>
        </div>
    );
}