import { useTranslation } from 'react-i18next';

export default () => {
  const { t, i18n } = useTranslation();
  return (
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">
        <header className="section-header"></header>
        <div className="row gx-0">
          <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
            <div className="content">
              <h1>{t('about_h1')}</h1>
              <h2>{t('about_h2')}</h2>
              <p>{t('about_text_p1')}</p>
              <p>{t('about_text_p2')}</p>
              <div className="text-center text-lg-start">
                <a href='./about' className="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
                  <span>{t('more_about_us')}</span>
                  <i className="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
            {/*<img src="assets/img/team/team.png" className="img-fluid h-100 w-100" alt="team"/>*/}
            <img src="assets/img/team/team.png" className="img-fluid" alt="team"/> 
          </div>

        </div>
      </div>
    </section>
  )
}