import { useTranslation } from 'react-i18next';

export default (props) => {
    const { t, i18n } = useTranslation();
    
    return(
        <section id="customers" className="clients">
            <div className="container" data-aos="fade-up">
                <header className="section-header">
                    <p> {t('customers_p')} </p>
                    <h2> {t('customers_h2')} </h2>
                </header>

                <div className="clients-slider swiper">
                    <div className="swiper-wrapper align-items-center">
                        <div className="swiper-slide"><a href="https://www.ipmetro.com/" target="_blank"><img src="assets/img/clients/client-1.png" className="img-fluid" alt="IP Metro" /></a></div>
                        <div className="swiper-slide"><a href="https://www.umicore.com/" target="_blank"><img src="assets/img/clients/client-2.png" className="img-fluid"  alt="Umicore" /></a></div>
                        <div className="swiper-slide"><a href="https://www.ausy.com/" target="_blank"><img src="assets/img/clients/client-4.png" className="img-fluid" alt="Ausy" /></a></div>
                        <div className="swiper-slide"><a href="https://www.alten.com/" target="_blank"><img src="assets/img/clients/client-3.png" className="img-fluid" alt="Alten" /></a></div>
                        <div className="swiper-slide"><a href="https://www.jeronimomartins.com/en/" target="_blank"><img src="assets/img/clients/client-5.png" className="img-fluid" alt="Jerónimo Martins" /></a></div>
                        <div className="swiper-slide"><a href="https://emp.millenniumbcp.pt/en/Pages/HomePage.aspx" target="_blank"><img src="assets/img/clients/client-6.png" className="img-fluid" alt="Millennium BCP" /></a></div>
                        <div className="swiper-slide"><a href="https://www.unitel.ao/" target="_blank"><img src="assets/img/clients/client-7.png" className="img-fluid" alt="Unitel" /></a></div>
                        <div className="swiper-slide"><a href="https://www.oney.pt/" target="_blank"><img src="assets/img/clients/client-8.png" className="img-fluid" alt="Oney" /></a></div>
                        <div className="swiper-slide"><a href="https://www.saesgetters.com/" target="_blank"><img src="assets/img/clients/client-9.png" className="img-fluid" alt="Saes" /></a></div>
                    </div>
                    <div className="swiper-pagination"></div>
                </div>
            </div>
        </section>
    );
}