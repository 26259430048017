import BasicLayout from "../../layouts/BasicLayout"
import BreadCrumbs from "../../components/common/BreadCrumbs"
import ScrollToTop from "../../components/common/ScrollToTop";
import BackToTopButton from "../../components/common/BackToTopButton";
import Contact from "../../components/Contact";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';

export default ({children}) => {
    const { t, i18n } = useTranslation();
    const [technologies, setTechnologies] = useState([]);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/assets/js/main.js";
        document.body.appendChild(script);

        fetch(`${process.env.REACT_APP_SERVER_URL}/server/technologies`, 
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(results => results.json())
            .then(data => {
                console.log('Server response: ', data)
                setTechnologies(data)
            })
    }, []);

    return (
        <ScrollToTop>
            <BasicLayout>
                <main>
                    <BreadCrumbs path='Development' title='Development Services' />

                    {/* "Empowering customer success" section */}
                    <div>
                        <header className="section-header-little-margin" data-aos="fade-up">
                            <p> Development Center </p>
                            <h2> Empowering customer success </h2>
                        </header>
    
                        <section id="dev" className="about">
                            <div className="container">

                                <div className="row gx-0">
                                    <div className="col-lg-6 content d-flex align-items-center" data-aos="fade-up" data-aos-delay="200" style={{backgroundColor:'#3C3535'}}>
                                        <p>If you are searching for the best software development companies, we are part of them. We offer <b>innovative, agile & competitive services</b> for custom software & application development.<br/><br/>
                                        We follow the industry's best practices for delivering the best outsourcing development services.<br/><br/>
                                        We are providing <b>several development services</b>, such as back-end, front-end or custom solution developments. <br/><br/>
                                        In any case we make sure that the <b>chosen technology is the right fit for your product</b> and we incorporate <b>quality assurance throughout all phases of software development</b>, ensuring the <b>highest standards of our products</b>. 
                                        </p>
                                    </div>

                                    <div className="col-lg-6 d-flex align-items-center" data-aos="fade-down" data-aos-delay="200">
                                        <img src="assets/img/dev.jpeg" className="img-fluid h-100 w-100" alt=""/>
                                    </div>
                                </div>

                            </div>
                        </section>
                    </div>

                    {/* "Your Offshore App Development Partner" section */}
                    <div>
                        <header className="section-header-little-margin" data-aos="fade-up">
                            <p> Development Center </p>
                            <h2> Your Offshore App Development Partner </h2>
                        </header>
    
                        <section id="dev" className="services" >
                            <div className="container" data-aos="fade-up" >
                                <div style={{textAlign:'center'}}>
                                <p>Our development services allow us to transform your digital dreams into reality by building goal oriented & personalized software and applications.</p>

                                <p hidden> Our developers work with dedication and enthusiasm to offer the most competent solutions specific to your business requirements. Our development services allow us to transform your digital dreams into reality by building goal oriented & personalized software and applications.</p>
                                <p hidden> Our development services allow us to transform your digital dreams into reality by building goal oriented & personalized software and applications. </p>
                                <p> Our developers work with <b>dedication</b> and <b>enthusiasm</b> to offer the most competent solutions specific to your business requirements. </p>
                                <p> They are driven by our project managers in order to guarantee the <b>solution is delivered as planned, bug free</b> and <b>with budget under control</b>. </p>

                                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4" style={{marginTop:'20px', marginBottom: '20px'}}>
                                    
                                    {/* 1st column card */}
                                    <div className="col" data-aos="zoom-in" data-aos-delay="100">
                                        <div className="card border-end h-100">
                                            <div className="col-sm-12 h-100">
                                                <div className="card-body">
                                                    <h4 className="card-title" style={{textAlign:'center'}}>Solution design</h4>
                                                    <br />
                                                    <p className="card-text">Our Tech lead and consultants are working together to define <b>the best architecture</b> and choose the <b>appropriate technology</b>  for your needs. </p>
                                                    <p className="card-text">We always seek the <b>right solution</b> to feet the requirements. </p>
                                                    <p className="card-text">We also define the <b>more effective methodology</b> and <b>best tools</b> to be used for the project. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {/* 2nd column card */}
                                    <div className="col" data-aos="zoom-in" data-aos-delay="200">
                                        <div className="card border-end h-100">
                                            <div className="col-sm-12 h-100 d-flex">
                                                <div className="card-body">
                                                    <h4 className="card-title" style={{textAlign:'center'}}>Full Stack Development</h4>
                                                    <br />
                                                    <p className="card-text">We are able to build an app <b>supporting you on every steps</b>, from helping building your functional specification to the solution deployment in production including all intermediary steps as such as : </p>
                                                    <ul >
                                                        <li>technical architecture</li>
                                                        <li>development</li>
                                                        <li>quality insurance</li>
                                                        <li>package deliveries</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    
                                    {/* 3rd column card */}
                                    <div className="col" data-aos="zoom-in" data-aos-delay="200">
                                        <div className="card border-end h-100">
                                            <div className="col-sm-12 h-100 d-flex">
                                                <div className="card-body">
                                                    <h4 className="card-title" style={{textAlign:'center'}}>System Integrations</h4>
                                                    <br />
                                                    <p className="card-text">Our company is experienced in system integration. </p>                          
                                                    <p className="card-text">We can <b>design integrations</b> between systems and develop any interface using API or custom code.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {/* 4th column card 
                                    <div className="col" data-aos="zoom-in" data-aos-delay="200">
                                        <div className="card border-0 h-100">
                                            <div className="col-sm-12 h-100 d-flex">
                                                <div className="card-body">
                                                    <h4 className="card-title">Project management</h4>
                                                    <br />
                                                    <p className="card-text">Our project managers are there to lead the projects in order to guarantee the solution is delivered as planned, bug free and with budget under control. </p>
                                                    <p className="card-text">Our PM are experienced consultant that can also help you write your functional specifications. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    */}

                                </div>

                                <p style={{textAlign: 'center'}}>We provide cost effective, customized and flexible engagements model to ensure the improvement of the businesses.
                                    <br/>
                                    <Link to='#contacts' >Please contact us.</Link>
                                    </p>
                                </div>
                            </div>
                        </section>
                    </div>

                    {/* "Our work methodology" section */}
                    <div>
                        <section id="customers" className="clients" >
                            <div className="container" data-aos="fade-up">
                                <header className="section-header-little-margin">
                                    <p> How We Work </p>
                                    <h2> Our work methodology </h2>
                                </header>
                                <div>
                                    <p> We apply the known <b>engineering and  code development best practices</b> to create scalable and modular solutions, we embrace the <b>agile environment</b>, and we are motivated to learn continuously by <b>running experiments to find the best solution</b>.  </p>
                                    <p> We apply the best method that correspond to your project to achieve your goal : </p>
                                    
                                    <div className="tab-content align-middle">
                                        <div className="line d-flex align-items-center mb-2">
                                            <i className="bi bi-check2"></i>
                                            <p><b>Scrum:</b> Development is done in Sprints, Iterations period must be defined with identified granular goals and backlogs. Suitable for any software development where needs cannot be clearly defined, or idea need to be tested before validation;</p>
                                        </div>
                                        <div className="line d-flex align-items-center mb-2">
                                            <i className="bi bi-check2"></i>
                                            <p><b>Kanban:</b> The work is done in very short Sprints, often daily. Regular meetings are held to discuss progress and priorities. The Kanban Board provides a clear representation of all project activities, responsible persons, and progress. Suitable for support and evolution;</p>
                                        </div>
                                        <div className="line d-flex align-items-center mb-2">
                                            <i className="bi bi-check2"></i>
                                            <p><b>Waterfall:</b> This approach is sequential : the next work is done when the previous one is finished and approved by the stakeholders. Suitable for projects with clearly defined and unchanging requirements, requiring formal approvals, projects with strict compliance requirements;</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-4 col-md-12 d-flex align-self-center">
                                        <img src="assets/img/services/sprint.jpeg" className="img-fluid" alt="Sprints work methodology" />
                                    </div>
                                    <div className="col-lg-4 col-md-12 d-flex align-self-center">
                                        <img src="assets/img/services/kanban.png" className="img-fluid" alt="Kanban work methodology" />
                                    </div>
                                    <div className="col-lg-4 col-md-12 d-flex align-self-center">
                                        <img src="assets/img/services/waterfall.png" className="img-fluid" alt="Waterfall work methodology" />
                                    </div>
                                </div>
                                
                            </div>
                        </section>
                    </div>

                    {/* "Empower your worforce with" section */}
                    <div>
                        <section id="portfolio" className="portfolio">
                            <div className="container" data-aos="fade-up">
                                <header className="section-header-little-margin">
                                    <p> Technology Stack </p>
                                    <h2> Empower Your Workforce With </h2>
                                </header>

                                <div className="text-center">
                                    <p> We have different levels of knowledge and can develop in a wide set of technologies. </p>
                                    <p> We work with different <b>programming languages, frameworks, DB’s, OS and solutions</b>. </p>
                                </div>

                                {/* TODO: fix problem with the filters and remove hidden 
                                <div hidden className="row" data-aos="fade-up" data-aos-delay="100">
                                    <div className="col-lg-12 d-flex justify-content-center">
                                        <ul id="portfolio-flters">
                                            <li data-filter="*" className="filter-active">All</li>
                                            <li data-filter=".filter-framework">Frameworks</li>
                                            <li data-filter=".filter-language">Languages</li>
                                            <li data-filter=".filter-database">Databases</li>
                                        </ul>
                                    </div>
                                </div>*/}

                                <div className="row gy-4 portfolio-container" data-aos="fade-up" data-aos-delay="200">
                                    {technologies.map( technology => { 
                                        return <TechnologyCard 
                                            filter = {technology.filter}
                                            imageName = {technology.imageName} 
                                            name = {technology.name} 
                                            description = {technology.description}
                                            external_link = {technology.external_link}
                                        /> 
                                    })}
                                </div>
                            </div>
                        </section>
                    </div>

                    {/* "Tell us about your project" section */}
                    <div>
                        <Contact 
                            pTitle="Get expert software engineers on demand" 
                            h2Title="Tell Us About Your Project" 
                            p="Your organization can engage with us in different ways, contact us for more information and get a solution that fits your organization."
                            margin='50px'/>
                    </div>

                    <BackToTopButton />
                        
                </main>
            </BasicLayout>
        </ScrollToTop>
    );
}

/**
 * This component represents the technology card.
 * It receives as props:
 * > filter - the filter to be applied to te card
 * > imageName - the name of the image (PS.: add the technology's image to assets/img/tech folder, please)
 * > name - technology's name
 * > description - technology's brief description
 * > external_link - technology's website link
 */
 const TechnologyCard = (props) => {
    const className = "col-lg-2 col-md-3 portfolio-item filter-" + props.filter
    const imageUrl = "assets/img/tech/" + props.imageName

    return(
        <div className={className}>
            <div className="portfolio-wrap">
                <img src={imageUrl} className="img-fluid" alt={props.name} />
                <div className="portfolio-info">
                    <h4> {props.name} </h4>
                    <p> {props.description} </p>
                    <div className="portfolio-links">
                        <a href={props.external_link} title="More Details" target="_blank"><i className="bi bi-link"></i></a>
                    </div>
                </div>
            </div>
        </div>
    )
}