import Form from './Form'
import { useTranslation } from 'react-i18next';

export default (props) => {
    const { t, i18n } = useTranslation();

    const styleHeight = '200px'
    const squareColor = '#E8AC14'

    const hideParagraph = props.p ? true : "hidden"
    
    return(
        <section id="contacts" className="contact" >
            <div className="container" data-aos="fade-up">
                <header className="section-header" style={{marginTop: props.margin}}>
                    <p>{props.pTitle}</p>
                    <h2>{props.h2Title}</h2>
                </header>

                <p {...hideParagraph} style={{textAlign: 'center'}}> {props.p} </p>
                
                <div className="row gy-4">
                    <div className="col-lg-6">
                        <div className="row gy-4">
                            <div className="col-md-6">
                                <div className="info-box"  style={{height: styleHeight}}>
                                    <i className="bi bi-envelope"></i>
                                    <h3>Email Us</h3>
                                    <p> info@hb-itconsulting.pt </p>
                                </div>
                            </div>
                            <div className="col-md-6 d-none d-md-block">
                                <div className="info-box" style={{background:squareColor, height:styleHeight}} />
                                    
                            </div>
                            <div className="col-md-6 d-none d-md-block">
                                <div className="info-box" style={{background:squareColor, height:styleHeight}} />
                                    
                            </div>
                            <div className="col-md-6">
                                <div className="info-box" style={{height: styleHeight}}>
                                    <i className="bi bi-link" ></i>
                                    <h3>Our Social Media</h3>
                                    <div className="social">
                                        <a target="_blank" style={{marginRight:'25px'}} href="https://www.facebook.com/HBITConsulting"><i className="bi bi-facebook"></i></a>
                                        <a target="_blank" href="https://www.linkedin.com/company/hb-it-consulting?challengeId=AQFs5QFYDk5QBgAAAX__tsZgAynEnVQlKc_vSlvaY0nZGE3A4YjxZ1AyOtdOiF8HAWLoflQRkjplnU6r-N-fBC7upCPrVTUfPg&submissionId=f0b2f49c-a25b-e316-47b6-5c3f0f842c30"><i className="bi bi-linkedin"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Form />

                </div>
            </div>
        </section>
    );
}